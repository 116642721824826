@import "variables.scss";
@import "fonts.scss";
@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk&family=Source+Serif+Pro:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk&display=swap");
html {
  font-size: 16px;
}
#root {
  margin-right: -6px;
}
body {
  font-family: $regular;
  overflow: scroll;
  background-color: #fff !important;
}
* {
  box-sizing: border-box;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  // background-color: $bgc-scrollbar-track;
}
::-webkit-scrollbar-track:hover {
  // background-color: $bgc-scrollbar-track-hover;
}
::-webkit-scrollbar-thumb {
  // background-color: $bgc-scrollbar-thumb;
  // @include border-radius(16px);
  // border: 1px solid $bdr-scrollbar-track;
}
::-webkit-scrollbar-thumb:hover {
  // background-color:$bgc-scrollbar-thumb-hover;
  // border: 1px solid $bdr-scrollbar-thumb-hover;
}
::-webkit-scrollbar-button {
  display: none;
}
button {
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    outline: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:visited {
    outline: none;
    box-shadow: none;
  }
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
/* my custom */
.reactinput div {
  justify-content: center;
}
.s_icons {
  position: relative;
}
.off_soon {
  position: absolute;
  background: #215350;
  color: rgb(255, 255, 255);
  font-weight: bold;
  padding: 1px 3px;
  width: 104px;
  right: 0;
  top: 0;
}

/* css by saran */

.register-left {
  background: $primary;
  h1 {
    color: white !important;
  }
  h5 {
    color: white !important;
  }
}

.getCare-choice {
  border: 1px solid $primary;
  display: inline-block;
  padding: 0.4rem 0.5rem;
  cursor: pointer;
  margin: 0.5rem;
  border-radius: 15px;
}
.login-main {
  // margin-left: 20px;
  overflow-y: scroll;
}
.login-main
  .register-left
  .left-content-outer
  .left-content-inner
  .left-content-dot
  span.active {
  background-color: white !important;
}
.bg-none {
  background: #fff !important;
  p {
    color: $primary !important;
  }
  .date-time-card {
    border: 1px solid $primary !important;
    position: relative;
    span {
      font-size: 12px;
      position: absolute;
      right: 7px;
      bottom: 0;
    }
  }
}
.choose-date-container {
  max-height: 350px;
  overflow-y: auto;
}
.service_review {
  max-height: 300px;
  .testi-content {
    h2 {
      font-size: 17px;
    }
    h5 {
      font-size: 15px;
    }
  }
  .slick-dots {
    bottom: 60px;
  }
}
.choose-date-container {
  .date-time-card {
    cursor: pointer;
    border: 1px solid white;
    color: white;
    padding: 1rem;
    width: 50%;
    margin: 10px auto;
  }
}
/* css by saran */

.rc-slider-track {
  background-color: $primary !important;
}
.rc-slider-handle {
  border: solid 2px $primary !important;
}

.errorMesage {
  line-height: 1.2em;
  color: #f44336 !important;
  font-size: 1em;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.successMesage {
  line-height: 1.2em;
  color: #26ad63;
  font-size: 1em;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup-main {
  .bg-background {
    display: flex;
    @media screen and (max-width: 467px) {
      display: none;
    }
  }
  .signup-inner {
    padding-top: 60px;
    @media screen and (max-width: 467px) {
      padding-top: 10%;
      background: $primary;
      height: 100vh;
    }
    h2 {
      @media screen and (max-width: 467px) {
        color: #fff;
      }
    }
    .btn {
      //  border-radius: 10px;
      min-width: 150px;
      margin-top: 5px;
      margin-bottom: 5px;
      @media screen and (max-width: 467px) {
        background: #fff;
        color: $primary;
        border: 1px solid $primary;
        margin-top: 10px;
      }
    }
    .signup-card {
      .slick-dots li button:before {
        font-size: 10px !important;
        color: $primary !important;
        @media screen and (max-width: 467px) {
          color: #fff !important;
        }
      }
      .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: $primary !important;
        @media screen and (max-width: 467px) {
          color: #fff !important;
        }
      }
      .card {
        box-shadow: none;
        background: transparent;
        border: none;
        .card-body {
          padding: 0px !important;
          margin: 0px !important;
          margin-right: 0px !important;
          margin-left: 0px !important;
          h3 {
            font-weight: 800;
            padding-top: 10px;
            @media screen and (max-width: 467px) {
              color: #fff;
            }
          }
          h6 {
            @media screen and (max-width: 467px) {
              color: #fff;
            }
          }
          img {
            max-width: 100%;
            max-height: 400px;
            margin: auto;
            // border-radius: 10px;
            @media screen and (max-width: 467px) {
              margin-top: 10px;
              max-height: 300px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  .left-content-inner {
    margin: 0rem 4rem;
    margin-bottom: 0;
    @media screen and (max-width: 467px) {
      margin: 0;
    }
    h1 {
      color: #fff;
      font-family: $regular;
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }
    h2 {
      color: #fff;
    }
    h5 {
      font-family: $thin;
      color: #fff;
      font-size: 16px;
    }
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 250px;
      margin: 1.5rem 0;
    }
    h4 {
      color: $tertiary;
    }

    h6 {
      color: #fff;
      font-size: 18px;
    }
    h2 {
      font-size: 1.5rem;
    }
    .left-content-dot {
      display: flex;
      justify-content: center;
      margin-top: 4.5rem;
      @media screen and (max-width: 467px) {
        margin: 0;
      }
      span {
        width: 18px;
        display: inline-block;
        height: 18px;
        border: 1px solid #707070;
        border-radius: 50px;
        margin-right: 1rem;
        &.active {
          background-color: $primary;
          @media screen and (max-width: 467px) {
            background-color: #fff;
          }
        }
      }
    }
  }
}
.payment_sec {
  border: 1px solid $primary;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  .pay_btn {
    background: $primary;
    color: #fff;
    font-size: 20px;
    padding: 5px 15px;
    margin-top: 10px;
    border-radius: 15px;
    display: inline-block;
    border: 1px solid $primary;

    &:hover {
      background: #fff;
      text-decoration: none;
      color: $primary;
      border: 1px solid $primary;
      cursor: pointer;
    }
  }
  h4 {
  }
  .consultation {
    color: $primary;
    font-size: 22px;
  }
  h6 {
    font-size: 10px;
  }
}
// login page Starts //
.login-main {
  // display: grid;
  // grid-template-columns: 30% 70%;
  .left-content-outer {
    @media screen and (max-width: 467px) {
      width: 100%;
      background: $primary;
      padding-bottom: 10px;
      padding-top: 10px;
    }
    .left-content-inner {
      padding: 5rem 3rem;
      margin-bottom: 0;
      @media screen and (max-width: 467px) {
        margin: 0;
      }
      h1 {
        color: $primary;
        font-family: $regular;
        margin-bottom: 0.5rem;
      }
      h5 {
        font-family: $thin;
        color: $primary;
        font-size: 16px;
      }
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 350px;
        margin: 1.5rem 0;
      }
      h4,
      h2 {
        color: $tertiary;
      }
      h6 {
        color: #898a8f;
      }
      .left-content-dot {
        display: flex;
        justify-content: center;
        margin-top: 4.5rem;
        @media screen and (max-width: 467px) {
          margin: 0;
        }
        span {
          width: 18px;
          display: inline-block;
          height: 18px;
          border: 1px solid #707070;
          border-radius: 50px;
          margin-right: 1rem;
          &.active {
            background-color: $primary;
            @media screen and (max-width: 467px) {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
  .form-control {
    height: 55px;
    font-size: 1.3rem;
  }
  .choose-language-outer {
    background-color: $primary;
    height: 100%;
    min-height: 100vh;
    padding-bottom: 3rem;
    @media screen and (max-width: 467px) {
      height: 80vh;
      min-height: 80vh !important;
    }
    @media screen and (max-width: 1024px) {
      min-height: 100vh;
    }
    &.getcare_sec {
      background-color: #ffffff;
      .choose-language-inner {
        p {
          text-align: center;
          color: $primary;
        }
      }
      .choose-language-card {
        box-shadow: 0 5px 30px rgba(32, 32, 32, 0.25);
      }
    }
    .choose-language-inner {
      padding: 6rem 4rem;
      padding-bottom: 0;
      @media screen and (max-width: 1024px) {
        padding: 6rem 2rem;
      }
      @media screen and (max-width: 991px) {
        padding: 6rem 0rem;
      }
      @media screen and (max-width: 467px) {
        padding: 3rem 2rem;
      }
      .otp_sec {
        .resend-link {
          a {
            font-size: 14px;
            color: #000;
            font-weight: 700;
          }
          button {
            background: transparent;
            border: none !important;
            font-size: 14px;
            color: #000;
            font-weight: 700;
            &:focus {
              outline: none !important;
            }
          }

          :hover {
            text-decoration: none;
            color: #fff;
          }
        }
        .asterisk {
          span {
            font-size: 24px;
            width: 20px;
            display: inline-block;
            color: #fff;
          }
        }
        h6 {
          font-size: 24px;
          margin-top: 25px;
          color: #ffdfcf;
          text-align: center;
        }
        h4 {
          color: #fff;
          font-size: 16px;
          text-align: center;
          span {
            color: #fff;
            font-size: 12px;
            text-decoration: underline;
          }
        }

        span {
          font-size: 17px;
          color: #fff;
        }
      }
      p {
        font-family: $medium;
        color: #215352;
        font-size: 20px;
        margin: 10px auto;
        line-height: 24px;
        background: #fff;
        border: 1px solid #ffff;
        padding: 10px 25px;
        width: 70%;
        @media screen and (max-width: 467px) {
          font-size: 18px;
        }
      }
      .choose-language-card {
        border-radius: 23px;
        background-color: #ffffff;
        margin: 1.5rem auto;
        padding: 1rem 1.5rem;
        padding-bottom: 3rem;

        .languagesec {
          max-height: 400px;
          overflow-y: auto;
        }
        hr {
          margin: 1rem auto 1rem auto;
          border: 0;
          border-top: 4px solid #f0f0f3;
          max-width: 125px;
          border-radius: 4px;
        }
        .choose-language-item {
          color: #898a8f;
          font-size: 12px;
          padding: 0.3rem 0;
          text-align: left;
          cursor: pointer;
          margin-bottom: 0.4rem;
          border-bottom: 1px solid #ededed;
          &.active {
            font-weight: 900;
            color: #000;
          }
          &.blocked {
            cursor: not-allowed;
            color: #80808061;
          }

          svg {
            margin-right: 0.4rem;
            color: $primary;
          }
        }
      }
    }
  }

  .assesement_card {
    border: 1px solif #000;
    background-color: #f8f5e7;
    width: 50%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-evenly;
    box-shadow: 0px 5px 15px rgba(32, 32, 32, 0.25);

    p {
      width: 60%;
      text-align: center;
      font-weight: 600;
      margin-top: 5px;
    }
    button {
      background-color: #fd836d;
      float: right;
      height: 30px;
      margin-top: 15px;
      border: none;
      border-radius: 2px;
      color: #fff;
      font-weight: bold;
    }
  }
  .mobile-number-outer {
    background-color: $primary;
    height: 100%;
    min-height: 100vh;
    @media screen and (max-width: 467px) {
      min-height: 78vh;
    }
    .mobile-number-inner {
      padding: 7rem 5rem;
      @media screen and (max-width: 467px) {
        padding: 10rem 15px;
      }
      @media screen and (max-width: 991px) {
        padding: 10rem 0px;
      }
      button {
        color: $primary;
        font-size: 20px;
        font-weight: 700;
        // border-radius: 15px;
        max-width: 200px;
        background: #fff;
        border: 0;
        &:hover {
          background: $primary;
          color: #fff;
          border: 1px solid #fff;
        }
      }
      h3 {
        color: #fff2ec;
        font-size: 20px;
        font-weight: 400;
      }
      h6 {
        font-size: 16px;
        color: #fff;
        margin-top: 1.7rem;
        margin-bottom: 1rem;
        font-weight: normal;
      }
      p {
        font-size: 14px;
        color: #fff;
        &.error {
          padding-left: 27px !important;
          @media screen and (max-width: 467px) {
            padding-left: 21px !important;
          }
        }
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input {
        background: transparent;
        color: $tertiary;
        font-weight: 600;
        border: 1px solid #f1d3c4;
      }
      ::placeholder {
        color: #fff;
      }
      .input_Icon {
        position: relative;
        span {
          position: absolute;
          top: -2px;
          right: 10px;
          background: #27ae60;
          border-radius: 50%;
          padding: 0 3px;
          color: #fff;
          svg {
            padding-bottom: 3px;
          }
        }
      }
    }
  }
}

.card_doc_ases {
  width: 40%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 200px;
  .inner_card_doc_ases {
    border: 1px solid hsl(0, 1%, 71%);
    background-color: #fff;
    width: 40%;
    padding: 5px;
    margin-top: 20px;
    margin: 20px;
    text-align: center;
  }
  button {
    width: 100%;
    border: none;
    background-color: #508373;
    color: #fff;
    font-weight: bold;
  }
}
.name_img_ {
  margin: 10px auto;
  margin-top: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  object-fit: cover;
}
.name_img {
  text-align: center;
  background-color: #215352;
  margin: 10px auto;
  margin-top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  .round_p {
    color: #fff;
    font-weight: bold;
    font-size: 24px;
  }
}
.edit_top_btn {
  float: right;
  margin-left: auto;
  margin-top: 20px;
  background: #215352;
  color: #fff;
  font-weight: bold;
  padding: 3px;
  border-radius: 2px;
  width: 50px;
  margin-right: 8px;
}
.header_h3 {
  color: #215352;
  font-family: $semibold;
  border-bottom: 1px solid #215352;
  margin-top: 40px;
  font-size: 18px;
}
.form-group {
  margin-bottom: 0px;
}
.pro_col {
  background-color: #dce3df;
}
.form-control:disabled {
  background: none !important;
}
.inp_col {
  background-color: #dce3df !important;
  text-align: right;
  float: right;
  :disabled {
    background-color: #dce3df !important;
  }
}
.prof_div {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #215352;
  margin: 0px;
  label {
    width: 55%;
    margin-top: 10px;
    font-family: $regular;
    margin-left: 5px;
  }
  input {
    border: none !important;
    outline: none !important;
    text-align: right;
    float: right;
    font-family: $regular;
  }
  .form-control:focus {
    outline: none !important;
    border: none !important;
  }
  form-control:disabled {
    background-color: #dce3df !important;
  }
  img {
    float: right;
    text-align: right;
    margin-left: 150px;
  }
}
.gen_modal_item {
  border: 1px solid #9e9b9b;
  padding: 6px;
  margin: 10px auto;
  font-family: $regular;
}
.documt_section_exc {
  // margin-left: 80px;
  // padding: 20px;
  margin-bottom: 60px;
  padding-top: 40px;
  position: relative;
  background-color: #fdfcfa;
  // background-image: url('../images//bg.png');
}
.documt_section {
  margin-left: 80px;
  padding-top: 40px;
  padding: 20px;
  margin-bottom: 40px;
  background-color: #fdfcfa;
  .add_doc_sec {
    margin-left: 430px;
    margin-top: 40px;
    background-color: #215352;
    border: none;
    color: #fff;
    font-weight: bold;
    padding: 5px;
    box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  }
  .mod_item_div {
    h4 {
      background-color: #66d9a7;
      color: #21262b;
      margin: 5px;
    }
  }
  .mod_item {
    background-color: #18c278;
    color: #21262b;
    margin: 5px;
  }
}
.card_doc {
  border: 1px solid hsl(0, 1%, 47%);
  background-color: #fff;
  width: 30%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-left: 200px;
  margin-top: 10px;
  // justify-content: space-evenly;
  // box-shadow: 0px 5px 15px rgba(32,32,32,.25);
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  position: relative;
  p {
    text-align: justify;
  }
  .inner_card_doc {
    display: flex;
    flex-direction: column;
    p {
      margin-left: 20px;
    }
    .firs_p {
      font-family: $semibold;
      margin-bottom: 2px;
      font-size: 16px;
    }
    .sec_p {
      font-family: $regular;
      font-size: 12px;
    }
  }
  .icon_div {
    align-self: center;
    position: absolute;
    right: 0;
    margin-right: 30px;
    display: flex;
    justify-content: space-between;
    .dow_icn {
      margin-right: 40px;
      cursor: pointer;
    }
  }
}
.modal_doc {
  width: 30%;
  border: 1px solid #215352;
  padding: 10px;
  margin-left: 200px;
  margin-top: 30px;
  border-radius: 3px;
  h4 {
    font-family: $bold;
    text-align: center;
    color: #215352;
    margin: 3px;
    font-size: 15px;
    text-decoration: underline;
  }
  .inpt_container {
    display: flex;
    flex-direction: column;
    span {
      color: red;
      font-weight: bold;
    }
    label {
      font-family: $regular;
      margin-top: 15px;
      font-weight: 700;
    }
    input:focus {
      outline: none !important;
    }
    .doc_type {
      border: none !important;
      border-bottom: 1px solid #215352 !important;
      padding: 4px;
    }
    .doc_name {
      border: none !important;
      border-bottom: 1px solid #215352 !important;
      padding: 4px;
      .doc_name:focus {
        border: none !important;
        outline: none !important;
      }
    }
    .doc_descr {
      padding: 10px;
    }
  }
  button {
    text-align: center;
    margin-left: 110px;
    margin-top: 10px;
    background-color: #215352;
    border: none;
    color: #fff;
    font-weight: bold;
    padding: 5px;
    box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  }
}
.card_doc_exc {
  border: 1px solid hsl(0, 1%, 47%);
  background-color: #fff;
  width: 45%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-left: 200px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  position: relative;
}

.ine_card_img {
  // width: 90px !important;
  // height: 120px !important;
  width: 15% !important;
}
p {
  font-weight: bold;
  font-family: $semibold;
}
img {
  align-self: center;
  object-fit: contain;
}
.icon_div_exc {
  width: 10%;
  position: absolute;
  right: 0;
  // float: right;
  // margin: 10px;
  // margin-right: 20px;
  margin-top: 35px;
  cursor: pointer;
}
.exc_show_more {
  // margin-left: 5px;
  // margin-right: 10px;
  width: 75% !important;

  .ex_p {
    font-family: $regular !important;
    margin-right: 10px !important;
    font-size: 12px;
  }
}
.goal_img {
  margin-left: 320px;
}
.card_doc_goal {
  border: 1px solid hsl(0, 1%, 47%);
  background-color: #fff;
  width: 40%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-left: 200px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  position: relative;
  .goal_inside {
    width: 18%;
  }
  .goal_show_more {
    width: 80%;
    .title_goal {
      font-family: $regular;
      font-weight: 600;
    }
  }
}
.goal_view_img {
  margin-left: 300px;
}
.goal_left {
  margin-left: 200px;
  margin-top: 20px;
  .top_b_goal {
    border: none;
    background-color: #ddefe5;
    cursor: pointer;
    box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  }
}
.row_btn_goal {
  display: flex;
  flex-direction: row;
}
.goal_inner_view {
  width: 46%;
  margin-left: 200px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .goal_btn {
    width: 20px;
    height: 20px;
    text-align: center;
  }
  .shd_goal {
    box-shadow: 0px 0px 2px 2px #000;
    z-index: 999;
  }
}
.gl_tile {
  font-family: $semibold;
  text-align: left;
  font-size: 15px;
  margin-left: 200px;
  width: 40%;
}
.gl_s_more {
  font-family: $regular !important;
}
.goal_ques_div {
  margin-left: 200px;
  margin-top: 10px;
  width: 40%;
  .goal_ques_p {
    font-family: $regular;
    opacity: 0.5;
  }
}
.reactinput {
  input {
    width: 1.5em !important;
    border: 0;
    border-bottom: 2px solid #fff;
    background: transparent;
    outline: transparent;
    color: $tertiary;
    padding: 0;
  }
  :focus {
    background: transparent;
  }
  span {
    width: 15px;
  }
}
.login-main {
  .btn-primary {
    color: $primary;
    font-size: 20px;
    font-weight: 700;
    // border-radius: 15px;
    max-width: 200px;
    background: #fff;
    border: 1px solid #fff;
    &:hover {
      background: $primary;
      color: #fff;
      border: 1px solid #fff;
    }
    &:active {
      background: $primary !important;
      color: #fff;
      border: 1px solid #fff;
      box-sizing: border-box;
    }
  }
}
p.error {
  padding-top: 10px;
  padding-left: 1rem;
  text-align: center;
  color: crimson !important;
}
.is-invalid {
  border: 1px solid crimson !important;
}
.customloading {
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 2000;
  left: 0;
  position: fixed;
  opacity: 0.8;
  top: 0;
  .spinner-border {
    position: absolute;
    top: 35%;
    border: 0.3em solid $primary;
    border-right-color: transparent;
    left: 50%;
  }
}

.register {
  min-height: 100vh;
  height: 100%;
  border-left: 1px solid #fcfbfb;
  @media screen and (max-width: 467px) {
    min-height: 96vh;
  }
  p.slider {
    font-size: 20px;

    font-weight: 800;
  }
  .head_question {
    padding: 30px 30px 10px 30px;
    max-height: 515px;
    min-height: 515px;
    overflow-y: auto;
    .question_layout {
      text-align: left;
      padding: 0.5rem;
      .ansdiv {
        cursor: pointer;
      }
      &:nth-child(even) {
        text-align: right;

        .ansdiv {
          width: auto;
          border-radius: 15px;
          border-bottom-right-radius: 0px;
          margin-bottom: 15px;
          background: #ffefe6;
          padding: 0.5rem 1rem;
          display: inline;
        }
      }
      img {
        max-width: 65px;
      }
    }
  }
  .bottomsec {
    // display: flex;
    padding: 15px;
    border-top: 1px solid $primary;
    text-align: right;
    @media screen and (max-width: 467px) {
      // display: inline-block;
    }
    img {
      max-width: 50px;
      max-height: 40px;
    }
    img.height {
      max-height: 20px;
    }
    .form-group {
      max-width: 100%;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      display: inline;
      input {
        border-radius: 15px;
      }
    }
    .submitbtn {
      cursor: pointer;
      background: $primary;
      width: 100%;
      cursor: pointer;
      margin-left: 10px;
      display: inline-block;
      border-radius: 50%;
      height: 40px;
      max-width: 40px;
      border: 1px solid #959595;
      float: right;
      svg {
        padding-top: 7px;
        padding-right: 5px;
        color: #ffddcc;
      }
    }
  }
}
.navmenu {
  background: #fcfbfb;
  padding: 5px;
  display: flex;
  svg {
    &:hover {
      color: $primary;
      cursor: pointer;
    }
  }
  .menu_lft {
    width: 100%;
    text-align: left;
  }
  .menu_right {
    width: 100%;
    text-align: right;
  }
}
.custom-radio {
  input[type="radio"] {
    opacity: 0;
  }
  :checked + label {
    background-color: $primary;
    border-color: #fff;
    color: #fff;
  }
  label {
    cursor: pointer;
    display: inline-block;
    background-color: #fff;
    padding: 5px 10px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border: 2px solid $primary;
    border-radius: 7px;
    cursor: pointer;
  }
}
.homecontainer {
  padding-bottom: 100px;
  overflow-y: scroll;
  background: #eef5f7 !important;
  min-height: 93vh;
  @media screen and (max-width: 1025px) {
    // margin-left: 6%;
  }
  @media screen and (max-width: 467px) {
    // margin-left: 13%;
  }
}
.login-main {
  .bg-background {
    background-color: $primary !important;
    height: 100%;
    .left-content-inner {
      h1 {
        color: #fff;
      }
      h2 {
        color: $tertiary;
      }
      h5 {
        color: #fff;
      }
      h2 {
        color: #fff;
        font-weight: 400;
        opacity: 0.9;
        font-size: 26px;
        margin: auto;
      }
      .left-content-dot {
        span {
          &.active {
            background-color: #fff;
          }
        }
      }
    }
  }
}
// .form-control:disabled {
//   background: #dce3df !important;
// }
.mw-100 {
  max-width: 100%;
  width: auto;
  height: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
span.flatpickr-weekday,
.flatpickr-months .flatpickr-month {
  background: $primary !important;
  color: $secondary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background: #ffffff !important;
  color: black;
}
.flatpickr-day.selected {
  background: $primary !important;
  border: 1px solid $primary !important;
}

.signup-main {
  .btn-primary {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    // border-radius: 15px;
    max-width: 200px;
    background: $primary;
    // border: 1px solid #fff;
    min-width: 150px;

    &:hover {
      background: #fff !important;
      color: $primary;
      border: 1px solid $primary;
    }
    &:active {
      background: #fff !important;
      color: $primary !important;
      border: 1px solid #fff;
      box-sizing: border-box;
    }
  }
  .slide_img {
    width: 250px !important;
    max-height: 400px !important;
  }
  .btn-danger {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    border-radius: 15px;
    max-width: 200px;
    min-width: 150px;
    &:hover {
      background: #fff !important;
      color: $primary;
      border: 1px solid #dc3444;
    }
    &:active {
      background: #fff !important;
      color: $primary !important;
      border: 1px solid #fff;
      box-sizing: border-box;
    }
  }
}
.bg-background {
  background-color: $primary !important;
  height: 100vh;
}
.row.bg-primary {
  background-color: $primary !important;
  height: 100%;
  .left-content-inner {
    height: 100vh;
    h1 {
      color: #fff;
    }
    h5 {
      color: #fff;
    }
    h6 {
      text-align: center;
    }
    .left-content-dot {
      span.active {
        background-color: #fff;
      }
    }
  }
}
.choose-language-outer {
  background-color: #fff;
}
.w-90 {
  width: 90%;
}
.customnav {
  box-shadow: 0 5px 30px rgba(32, 32, 32, 0.25);
  // position: fixed;
  // height: 100%;
  // z-index: 1000;
  .navbar-brand {
    padding-left: 50px;
  }
  nav {
    a {
      text-decoration: none;
    }
  }
  .sidenav---sidenav---_2tBP {
    background: $primary;
    position: fixed;
    height: 100%;
    z-index: 1002;
    @media screen and (max-width: 467px) {
      max-width: 45px;
      min-width: 45px;
    }
    button {
      @media screen and (max-width: 467px) {
        max-width: 45px;
        min-width: 45px;
      }
    }
    .sidenav---sidenav-nav---3tvij
      > .sidenav---sidenav-navitem---uwIJ-
      > .sidenav---navitem---9uL5T
      .sidenav---navicon---3gCRo {
      @media screen and (max-width: 467px) {
        max-width: 45px !important;
        min-width: 45px !important;
      }
      a {
        display: block;
        width: 100%;
      }
    }
  }
  .navbar {
    background-color: #eef5f7 !important;
    color: #fff;
    padding: 0.25rem 1rem !important;
    .navbar-brand {
      img {
        max-width: 86px;
      }
    }
    .nav-progress {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      min-width: 130px;
      .progress {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
          0 2px 4px 0 rgba(0, 0, 0, 0.08);
        background: #000;
        height: 0.5rem;
        border-radius: 20px;
        .bg-success {
          background: #fff !important;
        }
      }
    }
    .nav-item {
      a {
        color: #fff;
      }
    }
    .dropdown {
      list-style: none;
      .navbar-text {
        .userpic {
          border-radius: 50%;
          max-width: 50px;
          padding-left: 15px;
          img {
            max-width: 35px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
              0 2px 4px 0 rgba(0, 0, 0, 0.08);
            border-radius: 50%;
          }
        }
      }
      a {
        padding-top: 0;
        padding-bottom: 0;
      }
      .dropdown-menu {
        a {
          text-decoration: none;
        }
        button {
          &:hover {
            background: $primary;
            color: #fff;
          }
        }
      }
    }
  }
}
.newssec {
  padding-top: 30px;
  .api_btn {
    width: 100%;
    max-width: 100%;
    border: 1px solid $primary;
    // width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .accordion__item {
    .accordion__button {
      background: $secondary;
      color: #000;
      font-weight: 600;
      padding: 8px;
      font-size: 14px;
    }
    .accordion__panel {
      background: #f0f1f1;
      padding: 15px;
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
  .Collapsible {
    border-bottom: 1px solid #000;
    cursor: pointer;
    span {
      padding: 13px 10px;
      background: $primary;
      display: inline-block;
      color: #fff;
      width: 100%;
      font-weight: 600;
      font-size: 20px;
    }
    &.is-open .Collapsible__contentOuter {
      background: #f1f1f1;
      padding: 15px;
    }
    span.is-open {
      background-color: darken(#38cc70, 10%);
      color: #fff;
    }
    .Collapsible__contentOuter {
      padding: 0 15px;
      background: #f1f1f1;
      .Collapsible__contentInner {
        p {
          padding-top: 10px;
          margin: 0;
          font-size: 14px;
        }
        p:last-child {
          padding-bottom: 20px;
        }
      }
    }
  }
  h2 {
    font-size: 25px;
  }
}

.testimonialSec {
  padding-top: 30px;
  .card {
    border: 1px solid #d2d2dc;
    border-radius: 11px;
    -webkit-box-shadow: 0px 0px 5px 0px rgb(249, 249, 250);
    -moz-box-shadow: 0px 0px 5px 0px rgba(212, 182, 212, 1);
    box-shadow: 0px 0px 5px 0px rgb(161, 163, 164);
    min-height: 220px;
    cursor: pointer;
    .card-img-top {
      max-width: 100px;
      margin: auto;
      margin-top: 20px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px 0 rgba(0, 0, 0, 0.08);
      border-radius: 50%;
      border: 3px solid #fff;
    }
    .reviewSec {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      img {
        max-width: 22px;
      }
    }
    .card-text {
      text-align: center;
      display: -webkit-box;
      min-height: 84px;
      max-width: 220px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 14px;
    }
    img {
      max-height: 145px;
      border-top-left-radius: 11px;
      border-top-right-radius: 11px;
    }
  }
}
.mainsec {
  // background: #f8f8f8;
  min-height: 90vh;
  margin: 20px auto;
  margin-left: 400px;
  @media screen and (max-width: 467px) {
    margin-left: 13%;
  }
  .profilecard {
    padding-top: 25px;
    padding-bottom: 40px;
    .form-group {
      margin-bottom: 0px !important;
    }
    .nav {
      background: #fff;
      border: none;
      border-radius: 0.5rem;
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      .nav-item {
        color: $primary;
        &:hover {
          background: $primary;
        }
        a {
          color: $primary;
          padding: 0.6em;

          &:hover {
            color: #fff;
          }
        }
      }
    }
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      background: $primary;
      color: #fff;
    }

    .nav-tabs {
      background: #fff;
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      li {
        a {
          img {
            max-width: 40px;
          }
          color: $primary;
          &:hover {
            border: 0;
            background: $primary;
            color: #fff;
            cursor: pointer;
            border-radius: unset;
            padding: 0.56rem 1rem;
          }
          &.active {
            border: 0;
            background: $primary;
            color: #fff;
            font-weight: 700;
            cursor: pointer;
            padding: 0.57rem 1rem;
          }
        }
      }
    }
    .card {
      border: none;
      border-radius: 0.5rem;
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      min-height: 200px;

      .img_Sec {
        position: relative;
        max-width: 250px;
        margin: auto;
        padding-top: 20px;
        #file {
          display: none;
        }
        .card-img-top {
          max-width: 200px;
          max-height: 200px;
          margin: auto;
          border-radius: 50%;
        }
        a {
          position: absolute;
          bottom: 0;
          right: 20px;
          padding: 5px;
          background: #fff;
          z-index: 10;
          width: 40px;
          height: 40px;
          text-align: center;
          border-radius: 50%;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
            0 2px 4px 0 rgba(0, 0, 0, 0.08);
        }
      }
    }
    .btn-primary.payment {
      border-color: $primary;
      background: #fff;
      color: $primary;
      &:hover {
        color: #000;
      }
    }
    .btn-primary {
      background: $primary;
      border-color: $primary;
      border-radius: 3px;
      padding: 5px;
      min-width: 100px;
      max-width: 200px;
      text-transform: uppercase;
      font-weight: 600;
      margin: auto;
      display: block;
      &:hover {
        background: #fff;
        color: $primary;
      }
    }

    .btn-primary.complete {
      background: #28a745;
      border: 1px solid #28a745;
    }
    .btn-primary.complete:hover {
      background: #fff !important;
      color: #28a745;
    }
    .btn-primary.missed {
      background: #e8302b;
      border: 1px solid #e8302b;
    }
    .btn-primary.missed:hover {
      background: #fff !important;
      color: #e8302b;
    }
    .btn-primary.cancelled {
      background: #17a2b8;
      border: 1px solid #17a2b8;
    }
    .btn-primary.cancelled:hover {
      background: #fff !important;
      color: #17a2b8;
    }
  }
}
.service_card {
  .card {
    background: #fff;
    box-shadow: 0px 0px 5px 0px #a1a3a4;

    width: 95%;
    margin-bottom: 20px;
    padding: 20px;
    border: 0;
    border-radius: 7px;

    @media screen and (max-width: 467px) {
      width: 100%;
    }

    @media screen and (max-width: 1025px) {
      width: 100%;
    }
    .card-subtitle {
      width: 130px;
      // white-space: nowrap;
      max-width: 220px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      display: -webkit-box;
    }
    .card-title {
      font-size: 14px;

      width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    a {
      color: unset;
      text-decoration: none;
      img {
        max-width: 75px;
        max-height: 65px;
      }
    }
    &:hover {
      background: $tertiary;
      // transform: scale(1);
      width: 100%;
      transition: all 0.2s ease-in-out;
      a {
        color: $primary;
        h6 {
          color: #fff !important;
        }
        svg {
          color: #000;
        }
      }
    }
  }
}
.register_form {
  background: $primary;
  width: 50%;
  height: 100%;
  color: #fff;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #fdfcfa;
  // background-image: url('../images/bg.png');
  span {
    color: red;
    margin-left: 50px;
    position: absolute;
    top: 27%;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input_reg_div {
  padding: 40px;
  padding-top: 100px;
  padding-left: 50px;
}
h3 {
  margin-top: 40px;
  color: #000;
}
.first_name__ {
  width: 40%;
  margin-top: 10px;
  padding: 8px;
  padding-left: 20px;
}
.last_name__ {
  width: 40%;
  margin-top: 10px;
  padding: 8px;
  padding-left: 20px;
}
.email_input__ {
  width: 40%;
  margin-top: 10px;
  padding: 8px;
  padding-left: 20px;
}
.phone_number__ {
  width: 40%;
  margin-top: 10px;
  padding: 8px;
  padding-left: 20px;
}
.date_input__ {
  width: 12%;
  margin-top: 10px;
  padding: 8px;
  padding-left: 20px;
}
.month_input__ {
  width: 12%;
  margin-top: 10px;
  padding: 8px;
  padding-left: 20px;
}
.year_input__ {
  width: 16%;
  margin-top: 10px;
  padding: 8px;
  padding-left: 20px;
}
.pincode_input__ {
  width: 40%;
  margin-top: 10px;
  padding: 8px;
  padding-left: 20px;
}
.reg_radio__ {
  // margin-top: 40px;
  margin-left: 20px;
  p {
    width: 30%;
    font-size: 20px;
    border: 1px solid #fff;
    padding: 10px 20px;
    background-color: rgb(163, 159, 159);
    color: #fff;
  }
  small {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
  }
}
._reg_radio {
  width: 60% !important;
  text-align: center;
}
._reg_radio_ {
  width: 60% !important;
  background: #18c278 !important;
  color: #fff !important;
  font-weight: bold;
}
.btn_radion_reg {
  width: 60%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  .inner_radio_ {
    border: 1px solid #fff;
    background: #fff;
    padding: 4px 15px;
    margin: 5px;
    color: #000;
    border-radius: 2px;
  }
  .chng_radio {
    background: #18c278 !important;
    color: #fff !important;
    font-weight: bold;
  }
}
.reg_footer {
  position: absolute;
  right: 450px;
  bottom: 60px;
  button {
    background: #fff;
    padding: 5px 20px;
    font-weight: bold;
    border: none;
    box-shadow: 1px 1px 1px 1px #000;
  }
}
.confirm_appointment {
  margin: 20px;
  font-size: 25px;
  text-decoration: underline;
}
.appointment_container {
  justify-content: space-evenly;
  // border: 1px solid grey;
}
.appointment_container p {
  border: 1px solid grey;
  text-align: left;
  background-color: darkslategray;
  color: #000 !important;
  padding: 10px;
  width: 100% !important;
}
.appointment_container span {
  margin-right: 0px !important;
  float: right;
  width: 35%;
  color: #000 !important;
  background-color: #fff;
  // text-align: center!important;
}
.confirm_button {
  margin-top: 30px;
}
.cnfm_btn:hover {
  background-color: blueviolet;
}
.cnfm_btn {
  width: 20%;
  border-radius: 10px;
  padding: 8px 8px;
  color: #fff;
  background-color: #27ae60;
  outline: none;
  font-weight: bold;
}
.selected_service h2 {
  margin-left: 30px;
  margin-bottom: 40px !important;
  margin: 20px;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
}
.upcome_appoint {
  margin: 20px;
  text-align: center;
  font-weight: 500;
}
.my-validation-message::before {
  display: none;
}
.my-validation-message {
  width: 400px !important;
  margin-left: 10px !important;
}
.my-validation-message i {
  margin: 0 0.4em;
  color: #f27474;
  font-size: 1.4em;
}
.schedule_now {
  margin: 0 auto !important;
  margin-top: 20px !important;
}
.disable_coupon {
  margin: 10px;
  margin-left: -5px;
}
.disable_coupon p {
  margin-top: 5px;
  color: #26ad63;
}
.therapy_asses {
  overflow-y: scroll;
  overflow-x: scroll;
  height: "100%";
  width: "100%";
  background-color: #fdfcfa;
  // background-image: url('../images/bg.png');
}
.e_err_asses {
  margin-left: 50px !important;
  text-align: left !important;
  margin-top: 30px;
}
.headerText {
  font-size: 22;
  text-align: center;
  margin-top: 20;
  color: #fff;
}
.descr_asses_therapy {
  text-align: "left";
  margin-left: 15px;
  margin-top: 20;
  font-size: 18px;
  color: #000;
  font-weight: 500;
  padding: 4px;
  font-family: $regular;
}
.questn_asses_therapy {
  text-align: left;
  margin-top: 20px;
  font-size: 20px;
  color: #000;
  font-family: $regular;
  font-weight: 400;
  margin-left: 40px;
}
.radio {
  width: 80%;
  border: 1px solid #d9d9d9;
  padding: 10px;
  margin: 5px;
  // margin-left:30;
  margin-top: 6;
  background-color: #959595;
}
.footer_asses_therapy {
  margin-left: 30px;
  position: absolute;
  bottom: 30px;
  right: 55px;
  display: flex;
  flex-direction: row;
}
.next {
  align-self: flex-end;
  margin-left: 50;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  margin-top: -35;
  background-color: #fff;
  padding: 10;
}
.welcontainer {
  width: 98%;
  align-self: center;
  height: 100%;
}
.descHeader {
  font-size: 15;
  width: 95%;
  text-align: center;
  margin-top: 20;
  align-self: center;
  color: #fff;
}
.cont {
  width: 45%;
  align-self: center;
  padding: 15;
  margin-top: 20;
  background-color: "fff";
  display: flex;
  flex-direction: row;
  text-align: center;
}
.tex_input {
  font-size: 20px;
  background-color: #ffff;
  padding: 10px;
  margin-left: 50px;
  width: 35%;
}
.heds {
  margin: 4;
  font-size: 20;
  font-weight: bold;
  padding: 5;
  margin-top: 50;
}
.therapy_drop_ {
  margin-left: 80px;
  width: 280px;
  padding: 10px;
}
.therap_check_ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
  margin-left: 120px;
}
.showss {
  margin: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: justify;
  padding: 7px;
  margin-top: 120px;
  width: 40%;
  align-self: center;
}
.shows {
  justify-content: center;
  display: flex;
  align-items: center;
  flex: 1;
  align-content: center;
  align-self: center;
  margin-top: 30;
}
.dobcontainer {
  display: flex;
  flex-direction: row;
  margin-left: 65px;
  margin-top: 30px;
}
.date {
  width: 6%;
  padding: 14px;
  border-radius: 5px;
  background-color: #fff;
  margin: 2px;
}
.year {
  width: 10%;
  padding: 14px;
  border-radius: 5px;
  background-color: #fff;
  margin: 2px;
}
.textAr {
  align-self: center;
  margin-top: 20px;
  width: 65%;
  padding: 18px;
  border-radius: 5px;
  font-size: 16px;
  margin-left: 60px;
  background-color: #ffff;
}
.textAre {
  align-self: center;
  margin-top: "8%";
  border: 1px solid #fff;
  width: 92%;
  padding: 20;
  border-radius: 5;
  font-size: 16;
  background-color: #ffff;
}
.scale {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin-left: 10px;
  button {
    padding: 15px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    border: none;
  }
}
.range {
  padding: 16px;
  margin-top: 20px;
}
.textwell {
  margin-top: 20px;
  font-size: 16px;
  font-weight: "600";
  text-align: center;
}
.ratings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5;
  margin-top: 10;
  button {
    border: none;
    background: none;
  }
}
.step_3 {
  margin: 10px auto !important;
  img {
    width: 150px;
    height: 150px;
  }
  .bottom_card_ {
    width: 28%;
    border: 1px solid rgb(202, 199, 199);
    margin-left: 500px;
    margin-top: 120px;
    border-radius: 5px;
    .bottom_card_content {
      text-align: justify;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .bottom_card_img {
    // margin-left: 650px;
    img {
      width: 90px;
      height: 90px;
      margin-top: -70px;
      margin-left: 150px;
    }
  }
  button {
    margin-left: 640px;
    padding: 6px;
    width: 120px !important;
    background-color: #215352;
    margin-top: 40px;
    color: #fff;
    font-weight: bold;
  }
}
.step_2 {
  margin: 10px auto !important;
  img {
    width: 150px;
    height: 150px;
    margin-left: 630px;
  }
  p {
    text-align: center !important;
  }
  textarea {
    width: 30%;
    margin-left: 500px;
    padding: 30px;
    border: 1px solid #d9d9d9;
    font-size: 15px;
  }
}
.submit {
  background-color: #215352;
  margin-top: 18px;
  padding: auto;
  margin-left: 650px;
  padding-top: 12px;
}
.submitText {
  color: #fff;
  font-weight: bold;
  font-size: 16;
  margin: 3;
  text-align: center;
}
.weekcontainer {
  width: "95%";
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-top: 3;
  display: flex;
  flex-direction: row;
}
.weekRow {
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.selected_service {
  .card {
    background: #fff;
    box-shadow: 3px 3px 9px 0px #cfd1d2;
    width: 75%;
    padding: 20px 30px;
    border: 0;
    border-radius: 8px;
    margin: 5px auto;
    .card-title {
      font-size: 20px;
      margin-left: 120px;
      padding: 0px !important;
      flex: none !important;
      max-width: 100% !important;
    }
    .col-md-7 {
      flex: 0 !important;
      max-width: 100% !important;
    }
    .card-subtitle {
      font-size: 18px;
      text-align: left;
      margin-bottom: 20px;
      padding: 20px 15px 15px 50px;
      font-size: 18px;
      color: #000 !important;
    }
    .col_view {
      display: flex;
      flex-direction: column;
    }
    .paynow {
      display: flex;
      align-items: center;
      // z-index: 1000;
      justify-content: center;

      .btn-primary {
        color: #fff;
        background: $primary;
        font-size: 12px;
        // width: 30%;
        padding: 5px 10px;
        margin-right: 0px;
      }
    }
    .schedule-btn {
      margin: 10px auto !important;
    }
    a {
      color: unset;
      text-decoration: none;

      // img{
      //   width:150px;
      //   height: 100px;
      //   margin-left: -15px;
      //   margin-top: -10px;
      //   border-radius: 50px;
      // }
    }
    &:hover {
      // background: $primary;
      // // transform: scale(1);
      // width: 100%;
      // transition: all 0.2s ease-in-out;

      a {
        color: #000;
        h6 {
          color: #000 !important;
        }
        svg {
          color: #000;
        }
      }
    }
  }
}

.selec_img img {
  position: absolute;
  max-width: 120px;
  // z-index: 99;
  left: -80px;
  top: -15px;
}
._text_Asses {
  width: 98%;
  .col-md-6 {
    flex: 90% !important;
    max-width: 90% !important;
  }
}
.desc_text_Asses {
  button {
    background-color: #215352;
    color: #fff;
    font-weight: bold;
    padding: 5px;
    border-radius: 2px;
  }
}
.answrr_ques {
  width: 100% !important;
  h5 {
    font-size: 24px;
    // width: 100% !important;
    // margin-left: -80px !important;
    margin-bottom: 30px;
    // position: relative;
    // left: 2%;
    text-align: left;
  }
  .radio_style {
    // background: #f0f3f1;
    width: 30%;
    text-align: left;
    padding-left: -10px;
    margin: 8px;
    margin-left: 120px;
    cursor: pointer;
  }
  button {
    background: #f0f3f1 !important;
    border: none !important;
    padding: 8px;
    margin: 0px !important;
    font-size: 15px;
    font-family: $regular;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }
  .chhecked {
    background: #b0e4b1 !important;
    color: #fff !important;
  }
  .prev_div {
    margin-left: 70px;
  }
}
.cnt_btn {
  button {
    background-color: #215352;
    color: #fff;
    font-weight: bold;
    border-radius: 2px;
    margin-left: 130px;
    position: relative;
    top: 28px;
  }
}
.selected_service {
  .card::before,
  .card::after {
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    border-radius: 2px;
    height: 100%;
  }
  .card {
    transition: color 0.25s;
  }
  .card::before,
  .card::after {
    border: 2px solid transparent;
    width: 0;
    height: 0;
  }
  .card::before {
    top: 0;
    left: 0;
  }
  .card::after {
    bottom: 0;
    right: 0;
  }
  .card:hover {
    color: #0eb7da;
  }
  .card:hover::before,
  .card:hover::after {
    width: 100%;
    height: 100%;
  }
  .card:hover::before {
    border-top-color: $primary;
    border-right-color: $primary;
    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
  }
  .card:hover::after {
    border-bottom-color: $primary;
    border-left-color: $primary;
    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
      height 0.25s ease-out 0.75s;
  }
}

.visit-care {
  .card {
    background: #fff;
    box-shadow: 0px 0px 5px 0px #a1a3a4;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    border: 0;
    border-radius: 7px;
    p {
      font-size: 11px;
      color: $primary;
      margin: 0;
    }
    img {
      max-width: 50px;
    }
    .paynow {
      display: flex;
      align-items: center;

      .btn-primary {
        border: 1px solid $primary;
        font-weight: normal;
        font-size: 14px;
        width: 100%;

        padding: 5px;
        &:active {
          background: $primary;
        }
      }
    }
  }
}
.footer {
  padding: 10px !important;
  color: #fff;
  background: #000 !important;
  bottom: 0;
  width: 100%;
  position: fixed;
  z-index: 1500;
  // margin-top: 30px;
  // position: absolute;
  p {
    text-align: center;
    margin: 0;
  }
}
.plan_describe {
  padding: 40px 0;
  .prod_head {
    font-size: 28px;
  }
  .prod_short_desc {
    color: #21262b !important;
    font-size: 20px;
    text-align: justify;
  }
  .prod_long_desc {
    color: #0000008c !important;
    font-size: 16px;
    text-align: justify;
  }
  .pay_head {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 600;
    font-family: $semibold;
  }
  .pay_subhead {
    line-height: 14px;
    font-size: 14px;
    font-family: $regular;
    columns: #0000008c;
    input:focus {
      border: 0 !important;
      border-bottom: 2px solid $primary !important;
    }
  }
  .card {
    padding: 15px;
    .mw-100 {
      border-radius: 7px;
    }

    .prod_Desc {
      h3 {
        padding: 5px 0;
      }
      h5 {
        margin: 0;
      }
      img {
        max-width: 50px;
        max-height: 50px;
        border-radius: 50%;
        height: 50px;
        width: 50px;
      }
      p {
        padding: 0;
        line-height: 20px;
      }
    }
    img {
      max-width: 250px;
      &.product_banner {
        max-width: 100% !important;
      }
    }

    p {
      padding-top: 10px;
      color: #0000008c;
    }
    .btn-primary {
      background: $primary;
      border-color: $primary;
      border-radius: 10px;
      padding: 10px;
      // min-width: 200px;
      max-width: 200px;
      text-transform: uppercase;
      font-weight: 600;
      margin: auto !important;
      display: block;
      &:hover {
        background: #fff;
        color: $primary;
      }
    }
    .slider_sec {
      box-shadow: 0px 0px 5px 0px #a1a3a4;
      margin: 15px;
      max-width: 80%;
      padding: 15px;
      border-radius: 15px;
      &.doctor_list {
        max-width: 90%;
        min-height: 271px;
        img {
          max-width: 100%;
          padding-bottom: 10px;
        }
      }
      img {
        max-width: 22px;
        margin: auto;
      }

      p {
        text-align: center;
        display: -webkit-box;
        min-height: 84px;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 14px;
      }
      h5 {
        font-size: 18px;
        text-align: center;
      }
    }
  }
  .card-subtitle {
    color: #21262b !important;
  }
  .start_program {
    input[type="radio"] {
      opacity: 0;
    }
    h4 {
      color: $primary;
    }
    margin-top: 5px;
    img {
      max-width: 50px;
    }
    label {
      width: 70%;
      padding: 12px;
      border-radius: 10px;
      border: 1px solid #ededed;
    }
  }
}
.mw-100 {
  max-width: 100%;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px !important;
  }
}
.test_container {
  background: #07695070;
}
.testi-content {
  padding-top: 30px;

  h2 {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
  }
  h5 {
    color: #fff;
    font-weight: bold;
    text-align: center;
  }
}

.ansdiv {
  width: auto;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  margin-bottom: 15px;
  background: #ffefe6;
  padding: 0.5rem 1rem;
  display: inline;
}
.btn-primary {
  &.selectbtn {
    // margin: 0!important;
    background: #fff !important;
    border: 1px solid $primary;
    color: $primary !important;
    padding: 2px 6px;
    font-size: 14px;
    margin-top: 12px;
    &:hover {
      background: $primary !important;
      border: 1px solid $primary;
      color: #fff !important;
    }
  }
  :active {
    background: $primary !important;
    border: 1px solid $primary;
    color: #fff !important;
  }
}
.theme-primary {
  color: $primary !important;
}

.theme-bg-primary {
  background: $primary !important;
}
.appointmenthistory {
  .card {
    .card-title {
      padding-top: 5px;
    }
    .btn-primary.complete {
      margin: 0;
      background: #28a745 !important;
      border: 1px solid #28a745 !important;
    }
    ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      list-style: none;
      padding: 0;
      margin-bottom: 0.5rem;
    }
    li:nth-child(2) {
      font-weight: 600;
    }
    .accordion__item {
      .accordion__heading {
        border-radius: 10px;
      }
      .accordion__button {
        background: $secondary;
        color: #000;
        font-weight: 600;
        padding: 8px;
        font-size: 20px;
      }
      .accordion__panel {
        background: #fff;
        padding: 15px;
        p {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
    .Collapsible {
      border-bottom: 1px solid #000;
      cursor: pointer;
      span {
        padding: 13px 10px;
        background: $primary;
        display: inline-block;
        color: #fff;
        width: 100%;
        font-weight: 600;
        font-size: 20px;
      }
      &.is-open .Collapsible__contentOuter {
        background: #f1f1f1;
        padding: 15px;
      }
      span.is-open {
        background-color: darken(#38cc70, 10%);
        color: #fff;
      }
      .Collapsible__contentOuter {
        padding: 0 15px;
        background: #f1f1f1;
        .Collapsible__contentInner {
          p {
            padding-top: 10px;
            margin: 0;
            font-size: 14px;
          }
          p:last-child {
            padding-bottom: 20px;
          }
        }
      }
    }
    h2 {
      font-size: 25px;
    }
  }
}

.billinghistory {
  .card {
    background: #fff;
    border-radius: 12px;
    .pricesec {
      background: #fcedb3;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 10px;
      padding-right: 5px;
      p {
        font-weight: 600;
        margin: 0;
        line-height: 40px;
        font-size: 34px;
        span {
          font-size: 32px;
        }
      }

      h6 {
        font-size: 20px;
      }
    }
    ul {
      padding: 0;
      list-style: none;
      margin-bottom: 5px;
      li {
        display: inline-block;
      }
      li:nth-child(2) {
        font-weight: 600;
        padding-left: 5px;
      }
    }
    .btn-primary {
      margin: 0;
    }
  }
}
.paystatus.mainsec {
  padding-top: 25px;
  min-height: 90vh;
  img {
    max-width: 250px;
  }
  img.success {
    max-width: 120px;
  }
}
.allhistory {
  .indhistory {
    margin: auto;
    margin-top: 10px;
    border: 1px solid $primary;
    border-radius: 5px;
    padding: 10px 15px;
    h5 {
      span {
        // max-width: 70px;
        line-height: 30px;
        padding-bottom: 6px;
        margin: 0;
        border-color: $tertiary;
        border-bottom: 2px solid $tertiary;
      }
    }
  }
}
.cusicon {
  color: $tertiary;
}
.documentSec {
  img {
    max-width: 100px;
    max-height: 70px;
    margin: auto;
  }
  a {
    text-decoration: none;
    span {
      padding: 5px 0;
      text-align: center;
      font-size: 14px;
      max-width: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      margin: auto;
      cursor: pointer;
      color: $primary;

      &:hover {
        color: $tertiary;
        text-decoration: none;
      }
    }

    &:hover {
      color: $tertiary;
      text-decoration: none;
    }
  }
}
.doc_Card {
  margin-left: 150px;
  margin-top: 90px;
  padding: 0 !important;
}
.head_doc {
  background-color: #215350;
  color: #fff;
  font-weight: bold;
  td {
    border-right: 1px solid #fff !important;
  }
}
.download_sec {
  a {
    margin-left: 5px;
    margin-right: 5px;
    color: #1a63eb !important;
  }
}
.doc_table {
  width: 100% !important;
  border: 1px solid #215350;
  thead {
    border: 1px solid #215350;
    tr {
      border: 1px solid #215350;
      text-align: center;
    }
    td {
      border: 1px solid #215350;
      text-align: center;
    }
  }
  tbody {
    border: 1px solid #215350;
    tr {
      border: 1px solid #215350;
      text-align: center;
    }
    td {
      border: 1px solid #215350;
      text-align: center;
      padding: 5px 10px;
    }
  }
}
.indhistory {
  .tagsec {
    padding: 0 10px;
    // border-radius: 10px;

    p {
      display: flex;
      align-items: center;
      margin: 5px 0;
      h6 {
        display: block;
        width: 100%;
      }
      h6.obs {
        font-size: 13px;
      }
      span {
        width: 100%;
      }
      a {
        float: right;
        cursor: pointer;
        // background-color: $tertiary;
        padding: 5px;
        line-height: 10px;
        border-radius: 50%;
        font-size: 14px;
        color: $tertiary;
      }
    }
  }
}
.profilesec {
  .documentSec {
    a {
      span {
        max-width: 100%;
        overflow: unset;
      }
    }
    .profileIcon {
      a {
        span {
          max-width: 115px;
          overflow: hidden;
        }
      }
    }
    .myProfile {
      padding: 20px 15px;
      min-height: 150px;
      margin: 0 auto;

      &:hover {
        background: $tertiary;
        border-radius: 10px;
        cursor: pointer;
        color: #fff;
        img {
          min-height: 75px;
        }
        span {
          color: #fff;
        }
      }
    }
    .addProfile {
      padding: 20px 15px;
      min-height: 150px;
      &:hover {
        background: $tertiary;
        border-radius: 10px;
        cursor: pointer;
        img {
          min-height: 75px;
        }

        color: #fff;
        span {
          color: #fff;
        }
      }
    }
  }
}
.homeprofile {
  min-height: 90vh;
  .profilecard {
    display: flex;
    min-height: 80vh;
    align-items: center;
    padding: 0;
    margin: 5px auto;
    .profilesec {
      display: flex;
      align-items: center;
    }
  }
}
.W-100 {
  width: 100%;
}
.login-main.modal-dialog {
  .choose-language-outer {
    background: #fff !important;
    color: $primary;
    min-height: auto;
    .choose-language-inner {
      padding: 0;
      .otp_sec {
        p {
          color: $primary;
        }

        h5 {
          span {
            color: $primary;
          }
        }
        h6 {
          color: $tertiary;
        }
      }
      .reactinput input {
        border-bottom: 2px solid $primary;
        color: $tertiary;
      }
      .btn-primary {
        border: 1px solid $primary;
      }
      .resend-link button:hover,
      .resend-link a:hover {
        color: $tertiary;
      }
    }
  }
}

.doctorCard {
  width: 100%;
  background: $secondary;
  height: 55px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  span {
    text-align: center;
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
    color: $primary;
  }
}
.register.getcare {
  .head_question {
    .question_layout:nth-child(even) {
      text-align: left;
    }
    .question_layout {
      p {
        background: #eeeeee;
        border-radius: 10px;
        padding: 10px 5px;
        margin-bottom: 0px;
      }
      .ansdiv {
        margin-right: auto;
        p {
          width: auto;
          border-radius: 15px;
          border-bottom-right-radius: 0px;
          margin-bottom: 15px;
          background: transparent !important;
          padding: 10px 5px;
          display: inline;
        }
      }
    }
  }
}
.header_bar {
  width: 100%;
  height: 70px;
  display: inline-block;
  background-color: #215350;
  position: fixed;
  top: 0;
}
.header_list {
  margin-left: 10%;
}
.header_bar ul li {
  list-style: none;
  display: inline-block;
  margin: 10px;
  margin-left: 30px;
  margin-top: 25px;
  padding: 3px 7px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  &:hover {
    background-color: #fff !important;
    // border: 1px solid #fff !important;
    padding: 3px 7px;
    border-radius: 4px;
    color: #215350;
    cursor: pointer;
  }
}
.comment_container {
  height: 100%;
  overflow-y: scroll !important;
  background-color: #fff !important;
}
.community_container {
  background-color: #fff !important;
  height: 100vh;
  margin-bottom: -100px;
  font-family: "Poppins", sans-serif !important;
}
.img_comm_container {
  margin: 0 auto;
  background: #585442;
  width: 100%;
  height: 30%;
}
.top_banner {
  background: #215352;
  display: flex;
  flex-flow: row;
  margin-bottom: -15%;
}
.inside_top_banner {
  width: 40%;
  display: flex;
  flex-flow: column;
  margin-left: 2%;
  margin-top: 8%;
  color: #fff;
  h3 {
    font-size: 30px;
    margin-left: 10%;
    margin-bottom: 10px;
    font-family: $regular;
  }
  h4 {
    margin-left: -12%;
    margin-bottom: 15px;
    font-size: 25px;
    font-family: $regular;
  }
  p {
    margin-left: 10%;
    font-size: 18px;
    font-family: $regular;
  }
}
.community_banner_img {
  text-align: center;
  height: 300px;
  margin: 10px auto;
  // margin-bottom: -18%;
  // margin-left: 6%;
  background: #215350;
  width: 50%;
}
.ads_banner_community {
  width: 25%;
  // border: 1px solid #322432;
  position: sticky !important;
  top: 10px;
  height: 720px;
  right: 40px;
  // float: right !important;
  display: flex;
  flex-flow: column;
  // background: #215352;
  h4 {
    text-align: left;
    padding: 10px 10px;
    color: #fff;
  }
}
.card_banner {
  background: #fff;
  width: 90%;
  // padding: 70px 40px;
  border: 1px solid #000;
  border-bottom: 2px solid #3a3c3c;
  margin: 0 auto;
  margin-bottom: 40px;
  border-radius: 10px;
  img {
    height: 200px !important;
  }
}
.event_span {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: #fff;
  font-family: $regular;
}
.join_comm__ {
  margin-top: 20px;
  border-top: 10px solid #fff;
  padding: 10px 20px;
  color: #fff;
  h3 {
    margin-top: 5px;
    text-align: center;
    font-family: $regular;
  }
  p {
    font-family: $regular;
    text-align: center;
    font-size: 14px;
    padding: 4px 5px;
  }
  span {
    font-family: $regular;
    font-size: 14px;
  }

  button {
    width: 80%;
    margin: 0px auto;
    margin-bottom: 20px;
    margin-left: 30px;
    padding: 7px 15px;
    border-radius: 3px;
    font-weight: bold;
    color: #215350;
    background: #f1c546;
  }
  img {
  }
}
.Post_container {
  max-width: 50%;
  background-color: #f0f3f1;
  // border: 1px solid #215350;
  // box-shadow: 0px 0px 12px 5px #5e5c5c8c;
  margin: 15px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 180px;
  margin-bottom: 40px;
  border-radius: 5px;
}

.post_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  .inside_div {
    display: flex;
    flex-direction: row;
  }
}

.post_header h4 {
  margin-left: 10px;
  align-self: center;
  padding: 10px;
  font-weight: bold;
}
.post_header h5 {
  margin-left: -10px;
  align-self: center;
  padding: 10px;
  font-weight: lighter;
  font-size: 15px;
}
.post_header p {
  margin-left: 20px;
  align-self: center;
  padding: 10px;
  font-family: $regular;
}
.post_text {
  font-size: 16px;
  margin-left: 60px;
  margin-bottom: 35px;
  font-weight: 500;
  span {
    font-family: $regular;
  }
}
.share_modal {
  position: relative;
  margin-top: 150px;
}
.share_div {
  cursor: pointer;
  width: 80%;
  margin: 0 auto;
  background-color: #215350;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  font-size: 25px;
  // font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.share_div {
  li {
    list-style: none;
    border-top: #fff;
    border-bottom: #fff;
    margin: 8px 1px;
    border-left: #fff;
  }
  img {
    background-color: #fff;
    border: 1px solid #215350;
    border-left: none;
  }
}
.pull_up {
  margin-top: -35px !important;
}
.share_button {
  float: right;
  height: 35px;
  // width: 60px;
  margin-right: 15px;
  margin-top: 2px;
  border: none;
  background-color: #27ae60;
  color: #fff;
  border-radius: 5px;
  padding: 3px 10px;
}
.pull_down {
  margin-top: 50px !important;
}
.share_enable {
  background-color: grey !important;
}
.post_interaction {
  margin: 16px;
  display: flex;
  cursor: pointer;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  align-items: stretch;
  border-top: 0.2px solid rgb(26, 100, 45);
  padding-top: 20px;
  padding-bottom: 0px !important;
}
.footer_buttons {
  text-align: center;
  margin: 0 auto;
}
.community_next {
  height: auto;
  margin-left: -350px;
  padding: 4px 10px;
  background-color: #215350;
  //  border: 2px solid #215350;
  border-radius: 12px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 80px;
}
.post_like {
  // margin-left: 55px;
}
.small_img {
  display: flex;
  flex-direction: row;
  // border: .1px solid lightgray;
  // height: 80px;
}
.small_img img {
  margin-top: 0px;
  margin-left: 75px;
  object-fit: contain;
  border: 1px solid lightgray;
  padding: 3px 3px;
  max-width: 40px;
  max-height: 40px;
}
.close_img {
  position: relative;
  top: -2px;
  left: -14px;
}
.progress_Contianer {
  width: 20%;
  background-color: #fff;
  border: 1px solid #acdbd8;
  box-shadow: inset;
  height: 200px;
  position: absolute;
  top: 100px;
  left: 550px;
  z-index: 99;
}
.progress_post_p {
  position: relative;
  top: 65px !important;
  left: 106px !important;
}
.progress_post {
  position: absolute;
  bottom: 30% !important;
  z-index: 1500;
  left: 11%;
  width: 80% !important;
  // left: 25% !important;
}
.submit_post {
  // border: 1px solid grey;
  width: 50%;
  // height: 100px;
  margin-left: 180px;
  // max-height: 114px;
  margin-top: 20px;
}
.submit_post p {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 16px;
}
.close_show {
  background-color: #fff;
  margin-bottom: 20px;
  // border: 1px solid #215350;
  //  height: 200px;
  border-radius: 10px;
}
.inline_topics {
  width: 50%;
  margin-left: 190px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  // grid-template-columns: repeat(auto-fill,minmax(120px, 2fr));
  cursor: pointer;
}
.inline_p {
  margin-left: 190px;
  text-decoration: underline;
  font-size: 17px;
  font-weight: bold;
}
.inner_title {
  margin: 4px;
  border: 1px solid #215352;
  padding: 4px 7px;
  text-align: center;
  background: #258583;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  align-self: center;
}
.inner_title span {
  align-self: center !important;
}
.post_interaction span {
  font-size: 20px;
  position: absolute;
  top: -4px;
  right: -20px;
}
.post_text p {
  color: #000 !important;
}
.post_input {
  margin-left: 75px;
  width: 87%;
  height: 176px;
  outline: none !important;
  border: none;
  padding: 10px;
  font-size: 18px;
  margin-top: -50px;
  border: 1px solid #ddefe5;
  border-radius: 10px;
}
.post_top {
  background-color: #fff;
  // border: 2px solid #215352;
  width: 50%;
  height: 250px;
  margin-left: 180px;
  margin-top: 20%;
  font-size: 18px;
  font-family: "Courier New", Courier, monospace;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.4);
}
.tag_section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
.inner_topic {
  border: 1px solid #215352;
  background-color: white;
  margin: 10px;
  width: auto;
  text-align: center;
  padding: 2px 12px;
  border-radius: 5px;
  color: #215352;
  cursor: pointer;
  font-weight: 700;
}
.usr_dummy_img {
  width: 45px;
  height: 45px;
  margin-left: 18px;
  margin-top: 24px;
}
.post_top label {
  margin-top: 0px;
  margin-left: 70px;
  align-self: center;
  color: #215352;
}
.share_close {
  position: relative;
  left: 160px !important;
  top: -20px;
  cursor: pointer;
}
.modal_popup {
  position: relative;
  top: 50% !important;
  cursor: pointer;
}

.modal_share {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}
.comment_img {
  display: inline-block;
  position: relative;
  left: 40px;
}
.guide_name_checkbox {
  position: relative;
  left: -78%;
  top: 3px;
}
.nick_checkbox label {
  margin-left: 40px;
  margin-top: 3px;
}
.nick_checkbox {
  width: 38%;
  margin-left: 120px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 2px;
  background-color: #18c278;
  color: #fff;
  font-weight: bold;
}
.avatar_checkbox {
  border: 1px solid grey;
  width: 40%;
  margin-left: 120px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 7px;
  background-color: #71bcee;
  color: #fff;
  font-weight: bold;
  .guide_name_checkbox {
    margin-left: -8px;
  }
  label {
    margin-left: 35px;
    margin-top: 4px;
  }
}
.inside_icon {
  position: relative;
  top: -15px;
  left: 10px;
}
.guide_checkbox {
  position: relative;
  left: -176px !important;
  top: 4px;
}
.guideline_behaviour {
  display: flex;
  justify-content: center;
}
.nickname_container {
  height: 450px !important;
}
.guide_nickname {
  width: 90% !important;
  height: 90px;
  margin-left: 20px;
  text-align: center;
  font-size: 20px;
}
.name_btn {
  margin-top: 25px !important;
  position: relative;
  left: 40%;
  background-color: rgb(124, 119, 119);
  border: 0.02px solid lightgrey;
  padding: 4px 10px;
  border-radius: 2px;
}
.guide_modal_pop {
  // position: relative;
  // top:20% !important;
  // width: 70% !important;
}
.empty_div {
  min-height: 300px !important;
  margin-bottom: 40px;
  margin-top: -30px;
}
.sign_up_modal {
  position: relative;
  top: 100px !important;
  width: 45%;
  left: 27%;
  min-height: 380px !important;

  h3 {
    margin-top: 60px;
    text-align: center;
    font-weight: bold;
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
}
.close_sqaure {
  position: relative;
  left: 95% !important;
  cursor: pointer;
}
.community_sign {
  margin-left: 100px;
  position: relative;
  top: -80px;
  width: 200px !important;
  height: 80px !important;
  // border:1px solid green;
  background-color: #1a63eb;
  border-radius: 1px;
  padding: 10px 120px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  &:hover {
    cursor: pointer;
  }
}
.guide_btn_view {
  background-color: #226156 !important;
  color: #fff;
  font-weight: bold;
}
.name_btn_guide {
  background-color: #215352 !important;
  color: #fff;
  font-weight: bold;
}
.agree_div label {
  display: inline-block;
  align-self: center;
  right: -50px;
  position: relative;
  top: 3.5px;
}

.agree_div {
  border: 1px solid lightgrey;
  width: 50%;
  margin: 0 auto;
  padding: 6px 50px;
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: #18c278;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.guide_btn {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: rgb(124, 119, 119);
  border: 0.02px solid lightgrey;
  padding: 10px 50px;
  border-radius: 3px;
}
.avatar_container {
  // max-width: 900px;
  margin: auto;
}

.avatar_cards {
  cursor: pointer;
  margin-left: 15px;
  display: grid;
  grid-template-columns: repeat((5, 1fr));
  grid-gap: 20px;
  list-style-type: none;
  padding-left: 0;

  .avatar_card {
    position: relative;
    // border: 1px solid #ccc;
    text-align: center;
    overflow: hidden;
    background: #ffffff;

    img {
      display: block;
      max-width: 50px;
      object-fit: cover;
      width: 50px;
      height: 50px;
    }
  }
}

.comment_close_img {
  color: red;
  position: relative;
  top: -15px;
  left: -5px;
}
.post_top > input {
  display: none;
}
.reply_container {
  margin-left: 180px;
  position: fixed;
  z-index: 1200;
  width: 50%;
  bottom: 42px;
  border: 0.1px solid grey;
  background-color: #fff;
  border-radius: 5px;
}
.comment_post {
  overflow-y: scroll !important;
  height: 50%;
}
.reply_container input {
  width: 75%;
  height: 45px;
  border-radius: 5px;
  font-size: 18px;
  padding: 5px;
  border: none;
}
.reply_container input[type="file"] {
  display: none;
}
.reply_container label {
  margin-left: 25px;
}
.reply_button {
  font-size: 30px;
  color: rgb(10, 122, 34);
  font-weight: bold;
  margin-left: 40px;
  position: relative;
  left: 20px;
}
.reply_disable {
  color: grey !important;
}
.reply_input:focus {
  border: none !important;
  outline: none !important;
}
.input-coupon {
  min-width: 70% !important;
  margin: auto;
  max-width: 100%;
}
.offerings_div {
  margin-top: 12px auto;
  margin-top: 15px;
  text-align: center;
  font-family: $regular;
  p {
    font-size: 16px;
    font-family: $regular;
  }
}
.offerings_card {
  margin: 10px auto;
  text-align: center;
  // border: 1px solid #215352;
  width: 29%;
  height: 110px;
  border-radius: 15px;
  // background: #E4F2E9;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  h4 {
    margin-top: -28px;
    font-family: $regular;
    text-align: left;
    margin-left: 65px;
  }
}
.offerings_span {
  position: relative;
  top: -5px;
  font-size: 18px;
  margin-left: 20px;
  font-family: $regular;
}
.offerings_p_img {
  width: 60px;
  height: 60px;
  position: relative;
  left: -43%;
  top: 8px;
}
.offerings_arr1_img {
  width: 30px;
  height: 30px;
  position: absolute;
}
.offerings_arr2_img {
  width: 30px;
  height: 30px;
  position: absolute;
}
.offerings_arr3_img {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 18px;
  top: 40px;
}
.offerings_arr4_img {
  width: 30px;
  height: 30px;
  position: absolute;
}
.login-main.homepage {
  .choose-language-outer {
    background: $secondary;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
    min-height: 93vh;
    p {
      color: $tertiary;
      text-align: center;
    }
  }
}
.therapyhead {
  padding: 3rem 4rem;
  .bg-background {
    padding: 20px 30px !important;
  }
  h2 {
    font-size: 30px;
    color: $primary;
    text-align: center;
  }
  p.subtitle {
    font-size: 18px;
    color: #000;
    text-align: center;
  }
  .therapycard {
    margin-top: 5px;
    background-color: #c9e8d6;
    border-radius: 4px;
    label {
      // background: #fff;
      padding: 20px;
      border-radius: 15px;
      margin-bottom: 15px;
      align-items: center;
    }
  }
  .subtitle {
    font-family: $semibold;
    font-size: 24px !important;
    color: #215352 !important;
  }
  .therapy_card_ {
    border: 1px solid #215352;
    background: #fff;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    p {
      text-align: justify;
      font-family: $regular;
      margin-left: 12px;
      font-size: 12px;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-left: -35px;
      margin-top: 15px;
      object-fit: contain;
    }
  }
  .pay_head {
    font-size: 18px;
    font-weight: 600;
    font-family: $semibold;
    margin-left: 15px;
  }
  .pay_subhead {
    margin-top: 4px;
    font-size: 15px;
    margin-left: 15px;
    font-family: $regular;
    width: 100%;
    font-weight: 400;
    // columns: #0000008c;
    input:focus {
      border: 0 !important;
      border-bottom: 2px solid $primary !important;
    }
  }
  .apply_coupon {
    span {
      font-size: 9px;
      font-weight: bold;
      color: red;
      text-align: center;
      margin-left: 30px;
    }
    button {
      border: none;
      background-color: #215352;
      color: #fff;
      font-weight: bold;
      height: 30px;
      margin-top: 3px;
      font-size: 12px;
      padding: 2px !important;
      border-radius: 2px;
      box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
        inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
        inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    }
  }
  .disable_coupon {
    button {
      border: none;
      background-color: #215352;
      color: #fff;
      font-weight: bold;
      padding: 4px;
      border-radius: 2px;
      box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
        inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
        inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    }
  }
  .coupon_input {
    margin: 3px;
    margin-left: 15px;
    padding: 2px;
  }
  .coupon_input :focus {
    border: none;
    outline: none;
  }
  .therapy_card_treatmentplan {
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.42);
    border-radius: 4px;
    padding: 10px;
    // position: relative;
    h4 {
      font-family: $semibold;
      text-decoration: underline;
      color: #215352;
    }
    h5 {
      font-family: $regular;
      font-size: 16px;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
  .inside_therapy_exc {
    display: flex;
    flex-direction: row;
    p {
      font-family: $regular !important;
    }
  }
  // .documt_section{
  //   position: relative;
  //   overflow-y:'scroll';
  //   margin-top:'20px';
  //    margin-bottom:'100px';
  //   button{
  //     float: right;
  //     position: absolute;
  //     right: 0;
  //     margin-left: 220px;
  //   }
  // }
  .therapy_exc {
    border-top: 1px solid #215352;
    width: 100%;
    // position: relative;
    padding-top: 10px;
    font-family: $regular;
    button {
      float: right;
      margin-top: -40px;
      border: none;
      background-color: #215352;
      color: #fff;
      font-weight: bold;
      padding: 2px;
      box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
        inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
        inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
      cursor: pointer;
    }
  }
  .text-center {
    h5 {
      text-decoration: line-through;
      margin: 10px;
      font-family: $regular;
      font-size: 22px;
    }
    span {
      font-family: $regular;
      margin: 10px;
      font-size: 15px;
    }
    h4 {
      margin: 10px;
      font-family: $regular;
    }
  }
  // .documt_section{
  //   position: relative;
  //   overflow-y:'scroll';
  //   margin-top:'20px';
  //    margin-bottom:'100px';
  //   button{
  //     float: right;
  //     position: absolute;
  //     right: 0;
  //     margin-left: 220px;
  //   }
  // }
  .selectbtn {
    border: none;
    background-color: #215352 !important;
    color: #fff !important;
    border-radius: 2px !important;
    font-size: 10px !important;
    margin-top: 15px !important;
  }
  .therapy_see {
    font-family: $semibold;
    text-align: center;
    margin: 20px;
  }
  .therapy_stay {
    font-family: $semibold;
    text-align: center;
    margin: 20px;
    color: #215352;
    font-size: 16px;
  }
  .therapy_goal {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #215352;
    width: 100%;
    font-family: $regular;

    button {
      float: right;
      margin-top: -40px;
      border: none;
      background-color: #215352;
      color: #fff;
      font-weight: bold;
      padding: 2px;
      box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
        inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
        inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);

      cursor: pointer;
    }
  }
  .inline_p_sess {
    font-size: 12px;
    font-family: $regular;
    margin-left: 10px;
  }
  .suport_chat {
    // float: right;
    margin: 20px;
    // position: relative;
    button {
      background-color: #0eb7da;
      color: #fff;
      font-weight: bold;
      border: none;
      padding: 5px;
      cursor: pointer;
      float: right;
      position: absolute;
      right: 0;
      margin-right: 40px;
      margin-top: -20px;
      box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
        inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
        inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    }
  }
}
.bottomsec {
  a.btn-primary {
    background: $primary;
  }
  .btn-danger {
  }
}
.login-main {
  .therapyhead {
    padding: 3rem 4rem;
    &.chattherapy {
      border-radius: 20px !important;
      strike {
        font-weight: 700;
        color: grey;
        font-size: 16px;
      }
      h4 {
        font-weight: bold;
        font-size: 22px;
      }
      ul {
        list-style: square;
        margin-left: 30px;
        li {
          color: #fff;
        }
      }
      .bg-background {
        padding: 20px 30px !important;
      }
      p.subtitle {
        color: #fff;
      }
    }
  }
}
.no_document {
  margin: 30px auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  cursor: pointer;
  p {
    font-size: 20px;
    padding: 10;
    margin: 30px auto;
    text-align: center;
    width: 40%;
  }
  button {
    background: #215352;
    padding: 10px;
    color: #fff;
    font-weight: bold;
    width: 220px;
    margin: 10px auto;
    border-radius: 5px;
  }
}
.inner_no_document {
  background: #ddefe5;
  padding: 15px;
  margin: 30px auto;
  text-align: center;
  width: 30%;
  h4 {
    margin: 0 auto;
  }
  span {
    float: right;
  }
}
.first_row_prod {
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  margin-left: 25px;
  padding: 5px 10px 5px 20px;
  button {
    background: #215352;
    color: #fff;
    font-weight: bold;
    border-radius: 2px;
  }
}
.inner_first_row {
  padding: 20px;
  border: 1px solid #fff;
  border-width: 5px;
  /* margin: 10px; */
  /* margin-top: -15px; */
  // width: 50%;
  height: 40%;
  display: flex;
  align-items: center;
  flex-flow: column;
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  span {
    font-size: 20px;
    color: "#215352";
  }
  img {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 768px) {
  .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 200px !important;
  }
  .assesement_card {
    width: 80% !important;
    display: block !important;
    text-align: center;
  }
  .answrr_ques {
    width: 90% !important;
    margin-left: 35px;
  }
  .offerings_card {
    margin: 10px 10px 10px 90px;
    width: 75%;
  }
  .choose-date-container {
    max-height: 350px;
    overflow-y: auto;
    width: 90%;
    margin: 10px 10px 10px 35px;
  }
  .answrr_ques .radio_style {
    margin: 15px auto;
    width: 80%;
  }
  .choose-language-inner p {
    font-size: 16px !important;
    width: 80% !important;
  }
  .login-main .assesement_card p {
    font-size: 13px;
    width: 100%;
  }
  .assesement_card button {
    float: none !important;
  }
  .ads_banner_community {
    display: none;
  }
  .Post_container {
    max-width: 75% !important;
    margin-left: 100px !important;
  }
  .post_top {
    width: 75% !important;
    margin-left: 100px !important;
  }
  .post_input {
    width: 77% !important;
  }
  .inside_top_banner h4 {
    margin-left: -10px;
  }
  .inside_top_banner {
    width: 86%;
    margin-left: 10%;
  }
  .community_banner_img {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .documt_section {
    margin-left: 10px;
    padding: 20px;
    margin-bottom: 40px;
    .add_doc_sec {
      margin-left: 150px;
      width: 140px;
    }
  }
  .firs_p {
    margin-left: 2px;
  }
  .sec_p {
    margin-left: 2px;
  }
  .modal_doc {
    width: 85%;
    margin-left: 50px;
    button {
      margin-left: 70px;
    }
  }
  .post_top {
    margin-left: 60px !important;
  }
  .Post_container {
    margin-left: 62px !important;
  }
  .post_header h5 {
    font-size: 12px;
  }
  .post_header h4 {
    font-size: 15px;
  }
  .post_text span {
    font-size: 13px;
  }
  .post_text {
    font-size: 13px;
    margin-left: 60px;
    margin-bottom: 20px;
  }
  .post_input {
    width: 67% !important;
    margin-left: 67px;
    height: 150px;
  }
  .share_button {
    float: right;
    height: 32px;
    margin-right: 11px;
    margin-top: 9px;
    border: none;
    background-color: #27ae60;
    color: #fff;
    border-radius: 5px;
    padding: 3px 7px;
  }
  .inside_top_banner h3 {
    font-size: 20px;
  }
  .inside_top_banner h4 {
    font-size: 15px;
  }
  .top_banner p {
    text-align: center;
    margin-left: 30px;
    padding: 3px;
    width: 85%;
  }
  .scale {
    width: 80% !important;
    margin-left: 50px;
    button {
      padding: 11px;
      padding-top: 10px;
      padding-bottom: 5px;
    }
  }
}
#TooltipExample {
  margin-top: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  .no_document {
    width: 100%;
    p {
      width: 90%;
      margin: 30px auto;
      margin-left: 45px;
    }
    .inner_no_document {
      width: 50%;
      margin: 30px auto;
    }
  }
  .first_row_prod {
    width: 100%;
    .inner_first_row {
      width: 80%;
    }
  }
  .community_container {
    margin-left: 0 auto;
  }
  .top_banner {
    width: 100%;
  }
  .Post_container {
    max-width: 50%;
    margin-left: 220px;
  }
  .video_Player {
    width: 90% !important;
  }
  .inside_top_banner {
    h3 {
      margin-top: 20px;
      margin-left: 50px;
    }
    h4 {
      margin-left: 40px;
    }
    p {
      margin-left: 60px;
      text-align: center;
    }
  }
  .post {
    margin-left: -13%;
  }
  .post_input {
    width: 80%;
  }
  .post_top {
    margin-left: 25%;
  }
  .ads_banner_community {
    position: sticky;
    right: -12%;
    width: 20%;
  }
  .comment_close_img {
    margin-right: 20px;
  }
  .selected_service {
    h2 {
      width: 100%;
    }
  }
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100% !important;
  }
  .col-md-9 {
    flex: 0 0 100%;
    max-width: 100% !important;
  }
  .card {
    width: 70% !important;
    position: relative;
    left: 5%;
    text-align: center;
  }
  .card-title {
    font-size: 24px !important;
    margin-left: -4px !important;
    width: 100%;
  }
  .card-subtitle {
    font-size: 20px !important;
    margin-left: 35px !important;
    padding: 6px 10px !important;
    width: 100%;
  }
  .reply_container {
    width: 80% !important;
    margin-left: 10% !important;
    position: fixed;
  }
  .reply_button {
    position: relative;
    left: -2%;
  }
  .offerings_div {
    margin-left: 30px;
  }
  .offerings_card {
    width: 55%;
  }
}
.prof_inpt_file {
  display: none;
  margin-left: 210px;
  margin-top: 12px;
}
.inp_edit_ {
  margin-left: 255px;
}
.inp_edit {
  padding-top: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.lab_inp {
  margin-left: 210px;
  width: 55px;
  height: 55px;
  border: 1px solid #dcdcdc;
  border-radius: 50px;
  position: relative;
  background-color: #a1a3a4;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  // .row{
  //   width: 96%;
  //   margin: 0 !important;
  // }
  #TooltipExample {
    margin-top: 6px;
  }
  .login-main .therapyhead {
    padding: 1px;
    padding-left: 40px;
  }
  .therapy_card_treatmentplan {
    margin-left: -10px;
  }
  .coupon_input {
    width: 80px;
  }
  .disable_coupon {
    display: flex;
    flex-direction: row;
    button {
      padding-top: 1px !important;
      padding-bottom: 1px !important;
      font-size: 12px;
    }
  }
  .pay_head {
    margin-top: -14px;
  }
  .top__p {
    margin-top: -80px !important;
  }
  .post_header {
    .inside_div {
      display: flex;
      flex-direction: column;
      h4 {
        margin-left: -3px;
        text-align: left;
        margin-top: 3px;
      }
      h5 {
        margin-top: -20px;
        margin-left: -3px;
        text-align: left;
      }
    }
  }
  .step_3 {
    margin: 10px auto !important;
    img {
      width: 150px;
      height: 150px;
    }
    .bottom_card_ {
      width: 92%;
      margin-left: 20px;
      margin-top: 90px;
      .bottom_card_content {
        text-align: justify;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .bottom_card_img {
      // margin-left: 650px;
      img {
        width: 90px;
        height: 90px;
        margin-top: -70px;
        margin-left: 130px;
      }
    }
    button {
      margin-left: 140px;
    }
  }
  .post_header {
  }
  .step_2 {
    img {
      margin-left: 120px;
    }
    textarea {
      margin-left: 20px;
      width: 90%;
    }
    .submit {
      margin-left: 140px;
      margin-top: 40px;
    }
  }
  .sel_p_ {
    margin-left: 120px !important;
  }
  .tex_input {
    width: 80%;
  }
  .date {
    width: 20%;
  }
  .year {
    width: 30%;
  }
  .showss {
    width: 90%;
  }
  .therap_check_ {
    width: 90%;
    margin-left: 60px;
  }
  .textAr {
    width: 80%;
  }
  .range {
    width: 80%;
  }
  .card_doc_ases {
    width: 90%;
    margin-left: 50px;
    .inner_card_doc_ases {
      width: 80%;
    }
  }
  .pres_Card {
    width: 65%;
    .firs_p {
      font-size: 14px !important;
      margin-left: -2px !important;
    }
    .sec_p {
      margin-left: -2px !important;
      margin-bottom: 0px !important;
    }
  }
  .pres_icon {
    margin-right: 20px !important;
    margin-bottom: 10px;
    .pres_icn {
      margin-right: 20px !important;
    }
  }
  .card_doc_exc {
    width: 85%;
    margin-left: 50px;
    padding: 4px;
  }
  .exc_show_more {
    width: 50% !important;
  }
  .ine_card_img {
    width: 30% !important;
  }
  .icon_div_exc {
    margin-right: 10px;
  }
  .card_doc_goal {
    width: 85%;
    margin-left: 50px;
    .goal_inside {
      width: 30%;
      margin-top: 20px;
    }
  }
  .goal_img {
    margin-left: 48px;
    width: 280px !important;
    height: 280px !important;
  }
  .card_doc_ases {
    width: 90%;
    margin-left: 50px;
    .inner_card_doc_ases {
      width: 80%;
    }
  }
  .testi-content {
    h2 {
      margin-left: 40px;
    }
    h5 {
      margin-left: 20px;
    }
  }
  .card_doc {
    width: 98%;
    margin-left: 25px;
  }
  .first_row_prod {
    width: 100%;
    border: none;
    // flex-direction: column;
    .inner_first_row {
      width: 80%;
      // text-align: center;
      border: none;
      margin: 5px;
      //  align-items: center;
      button {
        //  align-items: center;
        //  margin-left: 30px;
      }
    }
  }
  .no_document {
    width: 100%;
    p {
      width: 90%;
      margin: 30px auto;
      margin-left: 45px;
    }
    .inner_no_document {
      width: 80%;
      margin-left: 65px;
    }
  }
  .slick-slider {
    position: relative;
    display: block;
    margin-left: 20px !important;
  }
  .login-main {
    // margin-left: 30px !important;
  }
  .community_container {
    margin-left: 0 auto;
  }
  .register_form {
    width: 100%;
    margin-top: 0px;
    background-color: #fdfcfa;
    // background-image: url('../images/bg.png');
    span {
      color: red;
      margin-left: 40px;
      position: absolute;
      top: 20%;
    }
  }
  .input_reg_div {
    cursor: pointer;
    padding: 30px;
    h3 {
      margin-top: 40px;
      color: #000;
    }
    .first_name__ {
      width: 70%;
      margin-top: 10px;
      padding: 8px;
      padding-left: 20px;
    }
    .last_name__ {
      width: 70%;
      margin-top: 10px;
      padding: 8px;
      padding-left: 20px;
    }
    .email_input__ {
      width: 70%;
      margin-top: 10px;
      padding: 8px;
      padding-left: 20px;
    }
    .phone_number__ {
      width: 70%;
      margin-top: 10px;
      padding: 8px;
      padding-left: 20px;
    }
    .date_input__ {
      width: 20%;
      margin-top: 10px;
      padding: 8px;
      padding-left: 20px;
    }
    .month_input__ {
      width: 20%;
      margin-top: 10px;
      padding: 8px;
      padding-left: 20px;
    }
    .year_input__ {
      width: 26%;
      margin-top: 10px;
      padding: 8px;
      padding-left: 20px;
    }
    .pincode_input__ {
      width: 70%;
      margin-top: 10px;
      padding: 8px;
      padding-left: 20px;
    }
  }
  .reg_radio__ {
    margin-top: 20px;
    margin-left: 20px;
    p {
      width: 50%;
      font-size: 20px;
      border: 1px solid #fff;
      padding: 10px 20px;
      background: #fff;
      color: #000;
    }
  }
  ._reg_radio {
    width: 60% !important;
  }
  ._reg_radio_ {
    width: 60% !important;
    background: #18c278 !important;
    color: #fff !important;
    font-weight: bold;
  }
  .btn_radion_reg {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .inner_radio_ {
      border: 1px solid #fff;
      background: #fff;
      padding: 4px 15px;
      margin: 5px;
      color: #000;
      border-radius: 2px;
    }
    .chng_radio {
      background: #18c278 !important;
      color: #fff !important;
      font-weight: bold;
    }
  }
  .reg_footer {
    position: absolute;
    right: 50px;
    bottom: 60px;
    button {
      background: #fff;
      padding: 5px 20px;
      font-weight: bold;
      border: none;
      box-shadow: 1px 1px 1px 1px #000;
    }
  }
  #root {
    overflow-x: hidden !important;
  }
  .selected_service {
    .card {
      width: 100% !important;
      margin: 20px auto !important;
      padding: 10px 15px !important;
      display: flex;
    }
    .therapy_c {
      margin-left: 55px !important;
      width: 85% !important;
    }
    .card-subtitle {
      margin-left: 0px !important;
      font-size: 13px !important;
      text-align: left;
      margin-bottom: 0px !important;
      padding: 20px 15px 15px 35px;
      font-size: 18px;
      color: #000 !important;
    }
  }
  .selec_img img {
    position: absolute;
    max-width: 50px;
    left: -20px;
    top: 25%;
  }
  .upcome_appoint {
    margin: 20px auto;
    margin-left: 20px;
  }
  .card-subtitle {
    // margin-left: 20px !important;
    font-size: 15px !important;
    width: 100%;
  }
  .card-title {
    margin-left: 70px !important;
    width: 100%;
    font-size: 18px !important;
    // text-align: center !important;
  }
  .btn-primary {
    padding: 5px 10px !important;
  }
  .paynow {
    width: 100% !important;
  }
  .header_bar {
    display: none;
  }

  .top_banner {
    // width: 96%;
    display: flex;
    margin-left: 25px;
    flex-flow: column;
    padding: 2px 4px;
    h3 {
      // width: 100%;
      margin-top: 70px;
      text-align: center;
      font-size: 20px;
      // margin-left: 55px;
    }
    h4 {
      width: 98%;
      margin: 20px auto;
      font-size: 15px;
    }
    p {
      text-align: center;
      margin-left: 25px;
      padding: 3px;
      width: 90%;
      font-size: 12px;
    }
    img {
      width: 98%;
      height: 180px;
    }
  }
  .inside_top_banner {
    width: 100%;
    margin: 0 auto;
  }
  .ads_banner_community {
    display: none;
  }
  .post {
    // margin-left: -30%;
    // padding: 0;
    width: 98%;
    margin: 10px auto;
  }
  .post_top {
    width: 85%;
    margin: 0px auto;
    margin-top: 70px;
    margin-left: 50px;
  }
  .Post_container {
    // margin-bottom: 10px;
    max-width: 85%;
    margin: 0 auto;
    margin-left: 50px;
    margin-bottom: 70px;
  }

  .post_input {
    width: 75%;
  }
  .video_Player {
    width: 90% !important;
  }
  .community_next {
    margin-left: 8%;
  }
  .comment_container {
    width: 98%;
    margin-left: 2%;
    margin-top: 3%;
  }
  .inline_p {
    margin-top: 20px;
    margin-left: 70px;
  }
  .inline_topics {
    margin-left: 100px;
  }
  .community_next {
    position: relative;
    top: -50px;
  }
  .comment_container h3 {
    margin-left: 40px !important;
  }
  .reply_container {
    width: 84%;
    margin-left: 12%;
    height: 50px;
    margin-bottom: 3px;
  }
  .reply_container label {
    //  margin-left: -45px;
  }
  .reply_input {
    width: 50% !important;
  }
  .reply_button {
    position: relative;
    left: 4%;
    top: 0;
  }
  .comment_img {
    display: inline-block;
    position: relative;
    left: 60px;
    margin-right: 20px;
  }
  .comment_close_img {
    position: relative;
  }
  .offerings_div {
    margin-left: 40px;
  }
  .offerings_card {
    width: 97%;
    margin-left: 9px;
  }
  .offerings_p_img {
    position: relative;
    left: -46%;
  }
  .offerings_arr2_img {
    position: relative;
    left: 28%;
  }
  .offerings_arr3_img {
    position: relative;
    left: 17%;
  }
  .offerings_arr4_img {
    position: relative;
    left: 17%;
  }
}

/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {
  ._cht_text {
    font-size: 10px;
  }
  .cht_img {
    width: 30px;
    height: 30px;
    object-fit: "cover";
  }
  .cht_img_ {
    width: 24px;
    height: 24px;
    object-fit: "cover";
  }
}
@media (min-width: 200px) {
  ._cht_text {
    font-size: 10px;
  }
  .cht_img {
    width: 30px;
    height: 30px;
    object-fit: "cover";
  }
  .cht_img_ {
    width: 24px;
    height: 24px;
    object-fit: "cover";
  }
}
@media (min-width: 320px) {
  ._cht_text {
    font-size: 7px;
  }
  .cht_img {
    width: 30px;
    height: 30px;
    object-fit: "cover";
  }
  .cht_img_ {
    width: 24px;
    height: 24px;
    object-fit: "cover";
  }
}
@media (min-width: 375px) {
  ._cht_text {
    font-size: 7px;
  }
  .cht_img {
    width: 30px;
    height: 30px;
    object-fit: "cover";
  }
  .cht_img_ {
    width: 24px;
    height: 24px;
    object-fit: "cover";
  }
}
@media (min-width: 425px) {
  ._cht_text {
    font-size: 10px;
  }
  .cht_img {
    width: 30px;
    height: 30px;
    object-fit: "cover";
  }
  .cht_img_ {
    width: 24px;
    height: 24px;
    object-fit: "cover";
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  ._cht_text {
    font-size: 8px;
  }
  .cht_img {
    width: 35px;
    height: 35px;
    object-fit: "cover";
  }
  .cht_img_ {
    width: 28px;
    height: 28px;
    object-fit: "cover";
  }
  .custom_margin {
    margin-left: 1rem !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  ._cht_text {
    font-size: 12px;
  }
  .cht_img {
    width: 50px;
    height: 50px;
    object-fit: "cover";
  }
  .cht_img_ {
    width: 40px;
    height: 40px;
    object-fit: "cover";
  }
  .custom_margin {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1024px) {
  ._cht_text {
    font-size: 12px;
  }
  .cht_img {
    width: 40px;
    height: 40px;
    object-fit: "cover";
  }
  .cht_img_ {
    width: 30px;
    height: 30px;
    object-fit: "cover";
  }
  .custom_margin {
    margin-left: 1rem !important;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  ._cht_text {
    font-size: 14px;
  }
  .cht_img {
    width: 50px;
    height: 50px;
    object-fit: "cover";
  }
  .cht_img_ {
    width: 40px;
    height: 40px;
    object-fit: "cover";
  }
  .custom_margin {
    margin-left: 1rem !important;
  }
}

.btn_Schdule {
  background: #215352 !important;
  color: #fff !important;
  font-weight: bold !important;
  font-family: "Hanken Grotesk" !important;
  padding: 6px 15px;
  font-size: 17px !important;
}
.btn_Schdules {
  background: #215352 !important;
  color: #fff !important;
  font-weight: bold !important;
  font-family: "Hanken Grotesk" !important;
  padding: 6px 15px;
  font-size: 12px !important;
}
.text_p_ {
  font-family: "Hanken Grotesk" !important;
}
.banner-text {
  display: block;
  text-align: center;
  position: absolute;
  top: -25px;
  left: 40%;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  background-color: #5c5c5c;
  color: #fff;
  width: 30px;
  height: 25px;
  margin-top: 22px;
  padding-top: 2px;
}
.text_medium_ {
  font-size: 16px;
}
.text_small_ {
  font-size: 12px;
}

.header_font_ {
  font-family: "Source Serif Pro";
}
.btn_ases_ {
  background-color: rgb(234, 230, 230);
  color: #000;
  border: 1px solid #d9d9d9;
  padding: 5px 12px;
}

.bg-bottom {
  background: #fff !important;
  border-top: 1px solid #dcdcdc !important;
  border-left: 1px solid #dcdcdc !important;
  border-right: 1px solid #dcdcdc !important;
  box-shadow: 0px 3px 3px 0px #707070 !important;
}
.bg-bottom input {
  border: none !important;
  outline: none !important;
  transition: none !important;
}
.bg-bottom input:focus {
  transition: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.text_color {
  color: #215352;
}

.sh_more {
  span {
    font-family: "Hanken Grotesk" !important;
    font-weight: bold;
    color: #215352;
  }
}
._text_color {
  color: #215352 !important;
}
.customnav .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  max-width: 200px !important;
}
.custom_border {
  border: 2px solid #c5d1d5 !important;
  border-radius: 5px !important;
  cursor: pointer;
  // border-color: cornflowerblue;
}
.error_ {
  font-size: 12px;
  font-family: "Hanken Grotesk";
  color: red;
  margin: 4px;
}
.req_ {
  color: red;
}
.skip_ {
  position: absolute;
  right: 20px;
  padding-top: 12;
  padding-left: 10;
  border-radius: 4;
  top: 45px;
  background-color: #215352;
  color: #fff;
}

.close_ {
  position: "absolute";
  right: 20;
  padding-top: 2;
  padding-left: 2;
  border-radius: 4;
  top: 90;
  background-color: "#fff";
}
.topcontainer_ {
  width: "100%";
  height: "100%";
  position: "relative";
}
.inputcontainer_ {
  width: "98%";
  margin-top: 1;
}
.toptext_ {
  font-family: "Hanken Grotesk";
  text-align: "left";
  font-size: 20;
  padding: 4;
  margin-left: 15;
  font-weight: 800;
}
.secondtxt_ {
  font-family: "Hanken Grotesk";
  text-align: "left";
  font-size: 15px;
  margin-left: 15;
  margin-top: 8;
}
.questiontxt_ {
  font-family: "Hanken Grotesk";
  opacity: 0.9;
  text-align: "left";
  font-size: 16;
  margin-left: 15;
  margin-top: 8;
}
.checkbox_ {
  display: "flex";
  flex-wrap: "wrap";
  flex-direction: "row";
  justify-content: "flex-start";
  margin: 10;
}

.checkboxtext_ {
  color: "#215352";
  font-family: "Source Serif Pro";
  padding: 5;
}
.checkboxtextbtn_ {
  border-radius: 10px;
  border-color: #8abebd !important;
  border-bottom: 10px solid #65b1b0;
  border-right: 10px solid #65b1b0;
  background-color: #fff;
  color: #215352;
  font-weight:600;
  border-width: 1.4px;
  user-select: none;
}
.btmtop_ {
  font-family: "Hanken Grotesk";
  text-align: "left";
  font-size: 14;
  margin-left: 15;
  margin-top: 8;
}
.btmtop_ ul{
  list-style: disc !important;
}
.btmsecondtop_ {
  font-family: "Hanken Grotesk";
  text-align: "left";
  font-size: 14;
  margin-left: 15;
  margin-top: 8;
}
.btmbtn_ {
  background-color: #215352;
  border-radius: 4;
  padding: 6;
  color: #fff;
}
.btmbtntxt_ {
  font-family: "Source Serif Pro";
  color: "#fff";
  text-align: "center";
  font-size: 16;
}
.radiobox_ {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.btnview_ {
  display: "flex";
  flex-direction: "row";
  justify-content: "space-between";
}
.prev_ {
  padding: 10px;
  margin-top: 10px;
  background-color: #ddefe5;
  border-width: 0.1px;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  color: #215352;
  border-color: #215352;
}
.numbr_ {
  border-radius: 50;
  margin: 20;
  margin-top: 10;
  background-color: "#f7f7f7";
}
.nmrtxt_ {
  font-family: "Source Serif Pro";
  padding: 10;
}
.workarea_ {
  border-width: 1;
  border-color: "#d7d7d7";
  width: "98%";
  align-self: "center";
  margin-top: 18;
  background-color: "#ffff";
  display: "flex";
  flex-direction: "column";
  border-radius: 4;
  margin-left: 8;
}
.header_ {
  font-family: "Source Serif Pro";
  font-size: 15;
  text-align: "center";
}
.cardfont_ {
  font-family: "Source Serif Pro";
  font-size: 14;
  padding: 10;
  text-align: "center";
}
.cardwrap_ {
  text-align: "center";
}
.fonts_ {
  font-family: "Source Serif Pro";
  font-size: 14;
  padding: 10;
  color: "#215352";
  opacity: 0.8;
  text-align: "left";
}
.img_ {
  width: "100%";
  height: 150;
}
.resultop_ {
  display: flex;
  flex-direction: row;
}
.reultimg_ {
  max-height: 500px;
  z-index: -99;
}
.imgtopview_ {
  position: relative;
  background-color: #edb561;
  border-width: 0.4px;
  border-radius: 4px;
  margin-top: 24px;
  border-color: #a8a5a5;
  z-index: 999 !important;
  max-width: 280px;
}
.imgtoptxt_ {
  text-align: "center";
  font-family: "Source Serif Pro";
}
.left_ {
  width: "100%";
}
.right_ {
  width: "100%";
}
.highlight_ {
  display: "flex";
  flex-direction: "row";
  flex-wrap: "wrap";
  margin-left: 15;
}
.highlightxt_ {
  border-radius: 4px;
  border-color: #215352 !important;
  background-color: #e7f0eb;
  color: #215352;
  font-weight: bold;
  border-width: 1.4px;
}
.hightxt_ {
  color: "#215352";
  font-family: "Source Serif Pro";
}
.bottombtn_ {
  background-color: #215352;
  color: #fff;
  border-radius: 4px;
}
.topicon_ {
  font-size: 13;
  font-family: "Hanken Grotesk";
  opacity: 0.7;
  margin-left: 15;
  margin-top: 80;
}
.white_color_ {
  color: #fff;
}
.lessopacity {
  opacity: 0.5;
}
.fullopacity {
  opacity: 1;
}
.medopacity {
  opacity: 0.8;
}
.welladdbtn_ {
  background-color: #35435f;
  font-family: "Hanken Grotesk";
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 4px;
}
.custom-tooltip {
  border: none !important;
  text-align: center;
  background: #fcfafa;
  padding: 4px;
}
.chart-wrapper {
  position: relative;
}

.chart-wrapper .chart {
  filter: blur(6px);
}

.chart-wrapper .content {
  position: absolute;
  top: 0;
  left: 50;
  right: 50;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  align-self: center;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 4px 0px;
  padding-left: 15px;
  background-color: #f1c646;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #215352;
  font: 700 14px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}
.wellbeing-assessment textarea{
  border:1.5px solid #ccc !important;
  padding: 4px 6px;
}
/* top left*/
.ribbon-top-left {
  top: -5px;
  left: -5px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 66px;
  background: #f1c646;
}
.ribbon-top-left::after {
  bottom: 66px;
  left: 0;
}
.ribbon-top-left span {
  right: 10px;
  top: 30px;
  transform: rotate(-45deg);
}
.customnav .sidenav---sidenav---_2tBP {
  overflow-y: scroll;
}
@media (max-width: 575.98px) {
  .btmbtn_ {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
}
.custom-fs {
  font-size: .8rem !important;
}

@media (min-width: 768px) {
  .custom-fs {
    font-size: 1rem !important;
  } 
  .secondtxt_ {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .custom-fs {
    font-size: 1rem !important;
  }
  .secondtxt_ {
    font-size: 14px;
  }
}

.full-screen-confetti {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
}

.custom-list {
  list-style-type: disc;
  padding-left: 20px;
}
.customnavs {
  div[role="navigation"] {
    margin-right: -10px;
    overflow-y: scroll !important;
  }
}
.scrollcontainer_sub h1,
.scrollcontainer_sub h2,
.scrollcontainer_sub h3,
.scrollcontainer_sub h4,
.scrollcontainer_sub h5,
.scrollcontainer_sub h6 {
  text-align: left;
  font-family: "Source Serif Pro";
}
